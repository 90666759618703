import { createSlice } from "@reduxjs/toolkit";
import { templatesInitialState } from "./templates.initialState";
import { templatesExtraReducers } from "./templates.extraReducers";

export const templatesSlice = createSlice({
  name: "templates",
  initialState: templatesInitialState,
  reducers: {
    clearFetchedTemplate: state => {
      state.fetchedTemplate = undefined;
    }
  },
  extraReducers: templatesExtraReducers
});

export const { actions: templatesActions } = templatesSlice;
