import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { ITemplatesState } from "shared/lib/models";
import { getTemplateThunk } from "./thunks/getTemplate.thunk";
import { getTemplatesThunk } from "./thunks/getTemplates.thunk";

export const templatesExtraReducers = (
  builder: ActionReducerMapBuilder<ITemplatesState>
): ActionReducerMapBuilder<ITemplatesState> => builder
  .addCase(getTemplatesThunk.fulfilled, (state, action) => {
    state.templates = action.payload.data;
  })
  .addCase(getTemplateThunk.fulfilled, (state, action) => {
    state.fetchedTemplate = action.payload;
  });
