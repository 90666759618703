import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button, CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";

import classes from "./ListProducts.module.scss";
import { useAppDispatch, useAppSelector } from "hooks";
import { ProductStatus } from "shared/lib/models";
import { productStatusMapper } from "shared/lib/utils/productStatusMapper";
import { getProductsThunk } from "redux/products/thunks/getProducts.thunk";
import dayjs from "dayjs";

const rowsPerPage = 10;

const ListProducts: React.FC = () => {
  const [statusFilter, setStatusFilter] = useState("");
  const [page, setPage] = useState(1);

  const { products, totalPages, loading } = useAppSelector(state => state.products);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleStatusFilterChange = useCallback(e => setStatusFilter(e.target.value), []);

  useEffect(() => {
    dispatch(getProductsThunk({ page: page - 1, pageSize: rowsPerPage }));
  }, [page, rowsPerPage]);

  return (
    <div className={classes.container}>
      <Button className={classes.newButton} color="primary" variant="contained" onClick={() => console.log("new")}>New</Button>
      <Grid spacing={3} container>
        <Grid xs={4} item>
          <FormControl variant="outlined" fullWidth>
            <InputLabel>Product Status</InputLabel>
            <Select id="status" label="Product Status" value={statusFilter} onChange={handleStatusFilterChange}>
              <MenuItem value="">None</MenuItem>
              {Object.values(ProductStatus).map(status => <MenuItem key={status} value={status}>{productStatusMapper[status]}</MenuItem>)}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <TableContainer style={{ position: "relative" }}>
        {loading && <div style={{ position: "absolute", backgroundColor: "rgba(255 255 255 / 0.7)", inset: 0, display: "grid", placeItems: "center", zIndex: 10 }}><CircularProgress /></div>}
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Product ID</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Book Type</TableCell>
              <TableCell>Attached to order on</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products.filter(product => statusFilter === "" || product.status === statusFilter).map(product => (
              <TableRow key={product.id}>
                <TableCell>{product.id}</TableCell>
                <TableCell>{productStatusMapper[product.status]}</TableCell>
                <TableCell>{product.book_name}</TableCell>
                <TableCell>{product.attached_to_wordpress_order_date && dayjs(product.attached_to_wordpress_order_date).format("DD/MM/YYYY HH:mm")}</TableCell>
                <TableCell>
                  <Button variant="contained" onClick={() => navigate(product.id)}>View</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        color="primary"
        count={totalPages}
        siblingCount={3}
        size="large"
        style={{ display: "flex", justifyContent: "center" }}
        variant="outlined"
        showFirstButton
        showLastButton
        onChange={(event, newPage) => setPage(newPage)}

      />
    </div>
  );
};

export default ListProducts;