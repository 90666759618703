import React, { useCallback, useEffect, useState } from "react";

export interface IUseAsyncReturn<T = unknown> {
  loading: boolean;
  error?: Error;
  value?: T
  refetch: () => void;
}

export function useAsync<T = unknown>(callback: (...args: unknown[]) => Promise<T>, dependencies: React.DependencyList = []): IUseAsyncReturn<T> {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error>();
  const [value, setValue] = useState<T>();

  const callbackMemoized = useCallback(() => {
    setLoading(true);
    setError(undefined);
    setValue(undefined);
    callback()
      .then(setValue)
      .catch(setError)
      .finally(() => setLoading(false));
  }, dependencies);

  useEffect(() => {
    callbackMemoized();
  }, [callbackMemoized]);

  return { loading, error, value, refetch: callbackMemoized };
}
