import React from "react";

import classes from "./Spinner.module.scss";

const Spinner: React.FC = () => {
  return (
    <div className={classes.spinner}>
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};

export default Spinner;
