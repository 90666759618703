import { createAsyncThunk } from "@reduxjs/toolkit";

import questionsApi from "api/questionsApi";
import { prefix } from "./config";
import { IDeleteQuestionPayload } from "shared/lib/models";
import { getQuestionsThunk } from "./getQuestions.thunk";

export const deleteQuestionThunk = createAsyncThunk<undefined, IDeleteQuestionPayload>(`${prefix} Delete Question`, async (payload, { rejectWithValue, dispatch }) => {
  try {
    await questionsApi.deleteQuestion(payload);
    await dispatch(getQuestionsThunk(payload));
    return;
  } catch(err) {
    console.log(err);
    return rejectWithValue(err);
  }
});
