import React, { ChangeEventHandler } from "react";
import classes from "./TextInput.module.scss";

interface ITextInputProps {
  value: string;
  onChange: ChangeEventHandler<HTMLInputElement>
  id?: string;
  placeholder?: string;
  label?: string;
  success?: boolean;
  error?: boolean;
  className?: string
  big?: boolean
}

const TextInput: React.FC<ITextInputProps> = ({ placeholder, big, label, id, className, value, onChange, success, error }) => {
  return (
    <div className={classes.wrapper}>
      {label && <label className={classes.label} htmlFor={id}>{label}</label>}
      <input
        className={`${classes.textInput} ${className} ${big ? classes.big : ""} ${success ? classes.success : ""} ${error ? classes.error : ""}`}
        id={id}
        placeholder={placeholder}
        type="text"
        value={value}
        onChange={onChange}
      />
    </div>
  );
};


export default TextInput;
