import { createAsyncThunk } from "@reduxjs/toolkit";

import questionsApi from "api/questionsApi";
import { IGetQuestionsPayload, IQuestion, Paginated } from "shared/lib/models";
import { prefix } from "./config";

export const getQuestionsThunk = createAsyncThunk<Paginated<IQuestion>, IGetQuestionsPayload>(`${prefix} Get Questions`, async (payload, { rejectWithValue }) => {
  try {
    return await questionsApi.getQuestions(payload);
  } catch(err) {
    console.log(err);
    return rejectWithValue(err);
  }
});
