import axiosInstance from "../config/axiosInstance";
import paths from "./paths";
import { IBook, Paginated } from "shared/lib/models";

export default {
  getBooks: async (): Promise<Paginated<IBook>> => {
    return await axiosInstance.get<Paginated<IBook>, Paginated<IBook>>(paths.books.get, {
      params: {
        showAll: true
      }
    });
  }
};
