import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TemplatePersonalization, TemplatePersonalizationRef } from "ui";
import { CoverType, IProduct } from "shared/lib/models";

import classes from "./EditProductPage.module.scss";
import Button from "../../../components/Button";
import productsApi from "../../../api/productsApi";
import { baseURL } from "../../../config/constants";

const EditProductPage = () => {
  const [product, setProduct] = useState<IProduct>();

  const { pageId, productId } = useParams<{productId: string; pageId: string | CoverType }>();
  const navigate = useNavigate();

  const templatePersonalizationRef = useRef<TemplatePersonalizationRef>(null);

  const template = useMemo(() => {
    if (["cover", "back_cover"].indexOf(pageId!) > -1) {
      return product?.[pageId as "cover" | "back_cover"];
    }

    return product?.pages.find(page => page.page_id === pageId);
  }, [product]);

  useEffect(() => {
    productsApi.getProduct({ productId: productId! }).then(setProduct);
  }, []);

  const handleSave = useCallback(async () => {
    if (!product) return;

    const template = templatePersonalizationRef.current?.getTemplate();
    let key;

    if (["cover", "back_cover"].indexOf(pageId!) > -1) {
      key = pageId as "cover" | "back_cover";
    } else {
      key = "template" as const;
    }

    await productsApi.updateProduct({
      [key]: template,
      productId: product.id
    });

    navigate(-1);
  }, [product]);

  if (!product || !template) return null;

  return (
    <div className={classes.container}>
      <TemplatePersonalization ref={templatePersonalizationRef} answers={product.answers} baseURL={baseURL} coverType={product.cover_type} product={product} template={template} />
      <Button label="Save" big onClick={handleSave} />
    </div>
  );
};

export default EditProductPage;