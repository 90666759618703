import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { IBooksState } from "shared/lib/models";
import { getBooksThunk } from "./thunks/getBooks.thunk";

export const booksExtraReducers = (
  builder: ActionReducerMapBuilder<IBooksState>
): ActionReducerMapBuilder<IBooksState> => builder
  .addCase(getBooksThunk.fulfilled, (state, action) => {
    state.books = action.payload.data;
  });
