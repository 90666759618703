import { createAsyncThunk } from "@reduxjs/toolkit";

import templatesApi from "api/templatesApi";
import { IAttachment, IUploadAttachmentPayload } from "shared/lib/models";
import { prefix } from "./config";

export const uploadAttachmentThunk = createAsyncThunk<IAttachment, IUploadAttachmentPayload>(`${prefix} Upload Attachment`, async (payload, { rejectWithValue }) => {
  try {
    return await templatesApi.uploadAttachment(payload);
  } catch(err) {
    console.log(err);
    return rejectWithValue(err);
  }
});
