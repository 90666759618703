import axiosInstance from "config/axiosInstance";
import paths from "./paths";
import { IElement } from "models";
import { 
  ICreateTemplatePayload, 
  IUpdateTemplatePayload, 
  ITemplate, 
  IUploadAttachmentPayload, 
  IAttachment, 
  Paginated, 
  IDeleteTemplatePayload, 
  IGetTemplatesPayload, 
  IGetTemplatePayload 
} from "shared/lib/models";

export default {
  getTemplate: async (payload: IGetTemplatePayload): Promise<ITemplate<IElement>> => {
    return await axiosInstance.get<ITemplate<IElement>, ITemplate<IElement>>(paths.templates.getOne.replace("{templateId}", payload.id));
  },
  getTemplates: async (payload: IGetTemplatesPayload): Promise<Paginated<ITemplate<IElement>>> => {
    return await axiosInstance.get<Paginated<ITemplate<IElement>>, Paginated<ITemplate<IElement>>>(paths.templates.get, {
      params: payload
    });
  },
  createTemplate: async (payload: ICreateTemplatePayload): Promise<ITemplate<IElement>> => {
    return await axiosInstance.post<ITemplate<IElement>, ITemplate<IElement>>(paths.templates.create, payload);
  },
  updateTemplate: async (payload: IUpdateTemplatePayload): Promise<ITemplate<IElement>> => {
    return await axiosInstance.put<ITemplate<IElement>, ITemplate<IElement>>(paths.templates.put.replace("{templateId}", payload.id), payload);
  },
  uploadAttachment: async (payload: IUploadAttachmentPayload): Promise<IAttachment> => {
    const formData = new FormData();
    formData.append("image", payload.file);
    return await axiosInstance.post<IAttachment, IAttachment>(paths.attachments.post, formData);
  },
  deleteTemplate: async (payload: IDeleteTemplatePayload): Promise<undefined> => {
    return await axiosInstance.delete(paths.templates.delete.replace("{templateId}", payload.id));
  }
};
