import { createSlice } from "@reduxjs/toolkit";
import { languagesInitialState } from "./languages.initialState";
import { languagesExtraReducers } from "./languages.extraReducers";

export const languagesSlice = createSlice({
  name: "languages",
  initialState: languagesInitialState,
  reducers: {},
  extraReducers: languagesExtraReducers
});

export const { actions: languagesActions } = languagesSlice;
