import React, { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSpring, animated } from "react-spring";
import { useGesture } from "@use-gesture/react";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import classes from "./Image.module.scss";
import { map } from "shared/lib/utils";
import { DefaultComponentProps } from "../utils";
import { width, height } from "shared/lib/utils/bookAspectRatio";

const getMeta = async (url: string) => {
  const img = new Image();
  img.src = url;
  await img.decode();
  return img;
};

export type ImageProps = {
  left: number;
  top: number;
  uri?: string;
  image_id?: string;
  width?: number;
  height?: number;
  rotation?: number;
  transformX?: string | number;
  transformY?: string | number;
  zoom?: string | number;
};

export type ImageComponentProps = DefaultComponentProps &
  ImageProps & {
  updateComponentProp: (
    id: string,
    key: keyof ImageProps | Record<string, unknown>,
    value?: unknown
  ) => void;
};

const ImageComponent: React.FC<ImageComponentProps> = ({
  id,
  component,
  answer,
  onClick: pushClick,
  baseURL,
  displayImage,
  domTarget,
  preview,
  updateComponentProp,
  ...props
}) => {
  const [imageSize, setImageSize] = useState({});

  const imageRef = useRef<HTMLImageElement>(null);

  const [{ x, y, zoom }, api] = useSpring(() => ({
    x: props.transformX ? +props.transformX : 0,
    y: props.transformY ? +props.transformY : 0,
    zoom: props.zoom ? +props.zoom : 1
  }));

  const style: CSSProperties = {
    width: map(props.width!, 0, width / 2, 0, domTarget?.clientWidth ?? width / 2) + "px",
    height:
      map(props.height!, 0, height / 2, 0, domTarget?.clientHeight ?? height / 2) + "px",
    top: props.top + "%",
    left: props.left + "%",
    transform: `rotate(${props.rotation}deg)`,
    position: "absolute",
    textAlign: "center",
    touchAction: "none"
  };

  const src = useMemo(
    () =>
      answer
        ? encodeURI(displayImage!)
        : encodeURI(component.image_url!),
    [answer, baseURL]
  );

  const getImageSize = useCallback(() => {
    return new Promise<{ width?: number; height?: number }>((resolve) => {
      const componentWidth = map(props.width!, 0, width / 2, 0, domTarget?.clientWidth ?? width / 2);
      const componentHeight = map(props.height!, 0, height / 2, 0, domTarget?.clientHeight ?? height / 2);

      const img = new Image();
      img.src = src;
      img.onload = () => {
        if (img.naturalWidth <= img.naturalHeight) {
          return resolve({
            width: componentWidth,
          });
        }

        return resolve({
          height: componentHeight,
        });
      };
    });
  }, [domTarget, props.width, props.height, src]);

  const saveDragPosition = useCallback((x, y) => {
    updateComponentProp(id, {
      transformX: x,
      transformY: y
    });
  }, [x, y, id, updateComponentProp]);

  const bind = useGesture({
    onDrag: ({ offset: [x, y] }) => {
      api.start({ x, y, immediate: true });
    },
    onDragEnd: ({ offset: [x, y] }) => {
      saveDragPosition(x, y);
    }
  }, {
    drag: {
      rubberband: false,
      bounds: {
        // TODO: Check how to handle boundaries
        // left: -((imageRef.current?.clientWidth ?? 0) - (props.transformX || 0)),
        // top: -((imageRef.current?.clientHeight ?? 0) - (props.transformY || 0)),
        // right: (props.transformX || 0) * -1,
        // bottom: (props.transformY || 0) * -1,
      },
      from: () => [x.get(), y.get()],
    }
  });

  const zoomInOut = useCallback(async (amp: number) => {
    const zoomLevel = zoom.get() + (0.1 * amp);
    api.start({ zoom: zoomLevel });
    updateComponentProp(id, "zoom", zoomLevel);
  }, []);

  useEffect(() => {
    (async () => {
      if (domTarget) {
        const size = await getImageSize();
        setImageSize(size);
      }
    })();
  }, [getImageSize, domTarget]);

  return (
    <div style={style}>
      <div style={{ width: "100%", height: "100%", overflow: "hidden" }}>
        <animated.img
          alt={`component-${id}`}
          {...bind()}
          ref={imageRef}
          draggable={false}
          src={src}
          style={{ x, y, zoom, ...imageSize }}
          onClick={() => pushClick && pushClick(component)}
        />
      </div>
      {!preview && (
        <div className={classes.zoom}>
          <div className={classes.button} onClick={() => zoomInOut(1)}>
            <svg fill="none" height="19" viewBox="0 0 19 19" width="19" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M19 10.8571H10.8571V19H8.14286V10.8571H0V8.14286H8.14286V0H10.8571V8.14286H19V10.8571Z"
                fill="white"
              />
            </svg>
          </div>
          <div className={classes.button} onClick={() => zoomInOut(-1)}>
            <svg fill="none" height="3" viewBox="0 0 22 3" width="22" xmlns="http://www.w3.org/2000/svg">
              <path d="M22 3H0V0H22V3Z" fill="white" />
            </svg>
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageComponent;
