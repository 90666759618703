import { createSlice } from "@reduxjs/toolkit";
import { settingsExtraReducers } from "./settings.extraReducers";
import { settingsInitialState } from "./settings.initialState";

export const settingsSlice = createSlice({
  name: "settings",
  initialState: settingsInitialState,
  reducers: {},
  extraReducers: settingsExtraReducers
});

export const { actions: settingsActions } = settingsSlice;
