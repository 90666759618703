import React from "react";
import { Provider } from "react-redux";
import { ToastProvider } from "react-toast-notifications";
import { PersistGate } from "redux-persist/integration/react";
import { createTheme, ThemeProvider } from "@material-ui/core";

import store, { persistor } from "store";
import Routes from "routes";
import "config/i18n";
import "styles/index.scss";

const theme = createTheme({
  palette: {
    primary: {
      main: "#ae24a3"
    }
  }
});

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ToastProvider newestOnTop={false} placement="top-right" autoDismiss>
          <ThemeProvider theme={theme}>
            <Routes />
          </ThemeProvider>
        </ToastProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
