import { createAsyncThunk } from "@reduxjs/toolkit";

import templatesApi from "api/templatesApi";
import { IElement } from "models";
import { IGetTemplatesPayload, ITemplate, Paginated } from "shared/lib/models";
import { prefix } from "./config";

export const getTemplatesThunk = createAsyncThunk<Paginated<ITemplate<IElement>>, IGetTemplatesPayload>(`${prefix} Get Templates`, async (payload, { rejectWithValue }) => {
  try {
    return await templatesApi.getTemplates(payload);
  } catch(err) {
    console.log(err);
    return rejectWithValue(err);
  }
});
