import { Answer, ComponentTypes } from "shared/lib/models";
import Text from "./components/Text";
import Image from "./components/Image";

export type DefaultComponentProps = {
  component: any;
  baseURL: string;
  domTarget: HTMLDivElement | null;
  preview?: boolean;
  onClick?: (component: any) => void;
  answer?: Answer;
  displayImage?: string;
  id: string;
}

export type ComponentMap = {
  [key in ComponentTypes]: unknown;
};

export const componentMap: ComponentMap = {
  [ComponentTypes.TEXT]: Text,
  [ComponentTypes.IMAGE]: Image
};
