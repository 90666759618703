import { combineReducers } from "@reduxjs/toolkit";
import { questionsSlice } from "./questions/questions.slice";
import { languagesSlice } from "./languages/languages.slice";
import { templatesSlice } from "./templates/templates.slice";
import { booksSlice } from "./books/books.slice";
import { productsSlice } from "./products/products.slice";
import { settingsSlice } from "./settings/settings.slice";

export default combineReducers({
  questions: questionsSlice.reducer,
  languages: languagesSlice.reducer,
  templates: templatesSlice.reducer,
  books: booksSlice.reducer,
  products: productsSlice.reducer,
  settings: settingsSlice.reducer
});
