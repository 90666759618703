import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { ILanguagesState } from "shared/lib/models";
import { getLanguagesThunk } from "./thunks/getLanguages.thunk";

export const languagesExtraReducers = (
  builder: ActionReducerMapBuilder<ILanguagesState>
): ActionReducerMapBuilder<ILanguagesState> => builder
  .addCase(getLanguagesThunk.fulfilled, (state, action) => {
    state.languages = action.payload.data;
  });
