import axiosInstance from "config/axiosInstance";
import {
  IGetProductPayload,
  IGetProductsPayload,
  IProduct,
  ISaveGeneratedPDFsPayload,
  IUpdateProductPayload,
  Paginated
} from "shared/lib/models";
import paths from "./paths";

export default {
  getProducts: async (payload: IGetProductsPayload): Promise<Paginated<IProduct>> => {
    return await axiosInstance.get<Paginated<IProduct>, Paginated<IProduct>>(paths.products.get, { params: payload });
  },
  getProduct: async (payload: IGetProductPayload): Promise<IProduct> => {
    return await axiosInstance.get<IProduct, IProduct>(paths.products.getOne.replace("{productId}", payload.productId));
  },
  generate: async (payload: ISaveGeneratedPDFsPayload): Promise<IProduct> => {
    return await axiosInstance.post<IProduct, IProduct>(paths.products.generate.replace("{productId}", payload.productId), {});
  },
  updateProduct: async (payload: IUpdateProductPayload): Promise<IProduct> => {
    return await axiosInstance.put<IProduct, IProduct>(paths.products.update.replace("{productId}", payload.productId), payload);
  }
};