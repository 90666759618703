import { createAsyncThunk } from "@reduxjs/toolkit";

import productsApi from "api/productsApi";
import { IGetProductsPayload, IProduct, Paginated } from "shared/lib/models";
import { prefix } from "./config";

export const getProductsThunk = createAsyncThunk<Paginated<IProduct>, IGetProductsPayload>(`${prefix} Get Products`, async (payload, { rejectWithValue }) => {
  try {
    return await productsApi.getProducts(payload);
  } catch(err) {
    console.log(err);
    return rejectWithValue(err);
  }
});
