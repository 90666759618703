import { Paginated } from "..";
import { IAnswer } from "./IAnswer";

export enum QuestionType {
  TEXT = "text",
  TEXTAREA = "textarea",
  CHOICE = "choice",
  CHECKBOX = "checkbox",
  RADIO = "radio",
  DATE = "date",
  FILE = "file",
  EVENT_DATE = "event_date"
}

export interface IQuestion {
  availableSideAnswer: boolean
  bookSlugs: string[]
  createdAt: string
  deletedAt: string
  description: string
  field_limit: boolean
  field_limit_count: number
  id: string
  label: string
  questionType: QuestionType
  required: boolean
  question_priority: number
  updatedAt: string
  availableAnswers: IAnswer[];
}

export type IPaginatedQuestions = Paginated<IQuestion>