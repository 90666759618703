import axiosInstance from "../config/axiosInstance";
import paths from "./paths";
import { ICreateQuestionPayload, IDeleteQuestionPayload, IGetQuestionPayload, IGetQuestionsPayload, IQuestion, IUpdateQuestionPayload, Paginated } from "shared/lib/models";

export default {
  getQuestion: async (payload: IGetQuestionPayload): Promise<IQuestion> => {
    return await axiosInstance.get<IQuestion, IQuestion>(paths.questions.getOne.replace("{questionId}", payload.id));
  },
  getQuestions: async (payload: IGetQuestionsPayload): Promise<Paginated<IQuestion>> => {
    return await axiosInstance.get<Paginated<IQuestion>, Paginated<IQuestion>>(paths.questions.get, { params: payload });
  },
  updateQuestion: async (payload: IUpdateQuestionPayload): Promise<IQuestion> => {
    return await axiosInstance.put<IQuestion, IQuestion>(paths.questions.put.replace("{questionId}", payload.id), payload);
  },
  deleteQuestion: async (payload: IDeleteQuestionPayload): Promise<null> => {
    return await axiosInstance.delete<null, null>(paths.questions.delete.replace("{questionId}", payload.questionId));
  },
  createQuestion: async (payload: ICreateQuestionPayload): Promise<IQuestion> => {
    return await axiosInstance.post<IQuestion, IQuestion>(paths.questions.post, payload);
  }
};
