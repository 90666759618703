import React from "react";

import classes from "./Modal.module.scss";

type ModalProps = {
  isOpen?: boolean
  onClose?: () => void
}

const Modal: React.FC<ModalProps> = ({ children, isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className={`${classes.modal} ${isOpen ? classes.isActive : ""}`}>
      <div className={classes.background} onClick={onClose} />

      <div className={classes.content}>
        {children}
      </div>
    </div>
  );
};

export default Modal;
