import { createAsyncThunk } from "@reduxjs/toolkit";

import templatesApi from "api/templatesApi";
import { IElement } from "models";
import { IUpdateTemplatePayload, ITemplate } from "shared/lib/models";
import { prefix } from "./config";

export const updateTemplateThunk = createAsyncThunk<ITemplate<IElement>, IUpdateTemplatePayload>(`${prefix} Update Template`, async (payload, { rejectWithValue }) => {
  try {
    return await templatesApi.updateTemplate(payload);
  } catch(err) {
    console.log(err);
    return rejectWithValue(err);
  }
});
