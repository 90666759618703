import React from "react";
import { Link, NavLink } from "react-router-dom";

import classes from "./Sidebar.module.scss";
import { ReactComponent as LogoSVG } from "assets/images/logo.svg";

const Sidebar: React.FC = () => {
  return (
    <div className={classes.sidebar}>
      <Link to="/">
        <LogoSVG />
      </Link>
      <div className={classes.links}>
        <NavLink className={({ isActive }) => isActive ? `${classes.active} ${classes.link}` : classes.link} to="/templates">Templates</NavLink>
        <NavLink className={({ isActive }) => isActive ? `${classes.active} ${classes.link}` : classes.link} to="/questions">Questions</NavLink>
        <NavLink className={({ isActive }) => isActive ? `${classes.active} ${classes.link}` : classes.link} to="/products">Products</NavLink>
        <NavLink className={({ isActive }) => isActive ? `${classes.active} ${classes.link}` : classes.link} to="/config">Config</NavLink>
      </div>
    </div>
  );
};

export default Sidebar;
