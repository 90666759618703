import React, { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from "react";
import Select, { GroupBase, Options } from "react-select";
import InputNumber from "rc-input-number";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Checkbox } from "@material-ui/core";
import { useToasts } from "react-toast-notifications";

import classes from "./CreateTemplate.module.scss";
import MultiValueLabel from "./MultiValueLabel";
import Spinner from "components/Spinner";
import Button from "components/Button";
import TextInput from "components/TextInput";
import { RootState } from "store";
import { IElement } from "models";
import { ICreateTemplatePayload, ITemplate, SelectOption } from "shared/lib/models";
import { componentMap, ComponentTypes, componentTypes } from "./helpers";
import { useAppDispatch } from "hooks";
import { useStateForInput } from "shared/lib/hooks";
import { getQuestionsThunk } from "redux/questions/thunks/getQuestions.thunk";
import { templateSchema } from "schemas/template.schema";
import { getLanguagesThunk } from "redux/languages/thunks/getLanguages.thunk";
import { getTemplatesThunk } from "redux/templates/thunks/getTemplates.thunk";
import { createTemplateThunk } from "redux/templates/thunks/createTemplate.thunk";
import { uploadAttachmentThunk } from "redux/templates/thunks/uploadAttachment.thunk";
import { getTemplateThunk } from "redux/templates/thunks/getTemplate.thunk";
import { getBooksThunk } from "redux/books/thunks/getBooks.thunk";
import { baseURL } from "config/constants";
import { updateTemplateThunk } from "redux/templates/thunks/updateTemplate.thunk";
import { templatesActions } from "redux/templates/templates.slice";

const templateTypes: SelectOption<ITemplate<IElement>["template_type"]>[] = [
  { label: "Page", value: "page" },
  {
    label: "Cover",
    value: "cover",
  },
  { label: "Back Cover", value: "back_cover" },
];

const CreateTemplate: React.FC = () => {
  const fetchedTemplate = useSelector<RootState, ITemplate<IElement> | undefined>(state => state.templates.fetchedTemplate);

  const [loading, setLoading] = useState(false);
  const [name, setName, resetName] = useStateForInput("");
  const [language, setLanguage] = useState<SelectOption<string> | null>(null);
  const [bookTypes, setBookTypes] = useState<Options<SelectOption<string>>>([]);
  const [components, setComponents] = useState<
    Array<ReturnType<typeof componentTypes[keyof typeof componentTypes]>>
  >([]);
  const [background, setBackground] = useState<File | string | undefined>();
  const [hardCoverBackground, setHardCoverBackground] = useState<File | string | undefined>();
  const [enablePriority, setEnablePriority] = useState(false);
  const [priority, setPriority] = useState(1);
  const [relatedAnswers, setRelatedAnswers] = useState<
    readonly SelectOption<string>[]
    >([]);
  const [templateType, setTemplateType] = useState<SelectOption<ITemplate<IElement>["template_type"]> | null>(null);
  const [backCover, setBackCover] = useState<SelectOption<string> | null>(null);
  const [preview, setPreview] = useState<File | string | undefined>();

  const backgroundStyles = useMemo<CSSProperties>(() => ({
    backgroundImage:
      background ? "url('" + ((typeof background === "string") ? background as string : URL.createObjectURL(background)) + "')" : "none",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  }), [background]);

  const languages = useSelector<RootState, GroupBase<SelectOption<string>>[]>(
    (state) => [
      {
        options: state.languages.languages.map((item) => ({
          label: item.name,
          value: item.code,
        })),
      },
    ]
  );

  const questions = useSelector<RootState, GroupBase<SelectOption<string>>[]>(
    (state) => state.questions.questions.filter(question => ["choice", "checkbox", "radio"].indexOf(question.questionType) > -1).map(question => ({
      label: question.label,
      options: question.availableAnswers?.map(answer => ({
        multiValueLabel: `${question.label} - ${answer.answer}`,
        label: answer.answer,
        value: answer.id
      })) ?? []
    }))
  );

  const templates = useSelector<RootState, GroupBase<SelectOption<string>>[]>(
    (state) => [
      {
        options: state.templates.templates.filter(item => item.template_type === "back_cover").map((item) => ({
          label: item.name,
          value: item.id,
        })),
      },
    ]
  );

  const bookSlugs = useSelector<RootState, GroupBase<SelectOption<string>>[]>(state => [
    {
      options: state.books.books.map(item => ({
        label: item.bookName,
        value: item.bookSlug
      }))
    }
  ]);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams<{ id?: string }>();
  const { addToast } = useToasts();

  const domTarget = useRef<HTMLDivElement>(null);

  const handleBackgroundChange = useCallback((e) => {
    if (!e.target.files[0]) return;
    setBackground(e.target.files[0]);
  }, []);

  const handleHardCoverBackgroundChange = useCallback((e) => {
    if (!e.target.files[0]) return;
    setHardCoverBackground(e.target.files[0]);
  }, []);

  const handleDeleteComponent = useCallback(
    (id: string) => {
      setComponents(components.filter((comp) => comp.id !== id));
    },
    [components]
  );

  const addComponent = useCallback(
    (type: keyof typeof componentTypes) => {
      setComponents([...components, componentTypes[type]()]);
    },
    [components]
  );

  const updateComponentProp = useCallback((id, key, value) => {
    setComponents([
      ...components.map((comp) =>
        comp.id === id
          ? {
            ...comp,
            props: {
              ...comp.props,
              ...(typeof key === "object" ? key : { [key]: value }),
            },
          }
          : comp
      ),
    ]);
  }, [components]);

  const handleSave = useCallback(async () => {
    try {
      setLoading(true);

      if (!templateType) {
        addToast("Template type is required!", {
          appearance: "error"
        });
        return;
      }

      if (!language) {
        addToast("Language is required!", {
          appearance: "error"
        });
        return;
      }

      let backgroundRes;
      if (background && typeof background !== "string") {
        backgroundRes = await dispatch(uploadAttachmentThunk({ file: background! })).unwrap();
      }

      let hardCoverBackgroundRes;
      if (hardCoverBackground && typeof hardCoverBackground !== "string") {
        hardCoverBackgroundRes = await dispatch(uploadAttachmentThunk({ file: hardCoverBackground! })).unwrap();
      }

      const payload: ICreateTemplatePayload = {
        name,
        related_answer_ids: [...relatedAnswers.map(answer => answer.value)],
        template_type: templateType.value,
        language: language.value,
        elements: components.map(comp => ({ element_type: comp.type, element_properties: comp.props })),
        background: backgroundRes?.id || (fetchedTemplate && fetchedTemplate.background.background_id) || undefined,
        hard_cover_background: hardCoverBackgroundRes?.id || (fetchedTemplate && fetchedTemplate.hard_cover_background?.background_id) || undefined,
        book_slugs: bookTypes.map(item => item.value),
        back_cover_id: backCover?.value,
        priority: enablePriority ? priority : undefined,
        has_user_input: components.some(component => !!component.props.relatedQuestion)
      };
      await templateSchema.validate(payload);
      if (params.id) {
        await dispatch(updateTemplateThunk({ id: params.id, ...payload })).unwrap();

        addToast("Template updated!", {
          appearance: "success",
        });
        navigate("/templates");
      } else {
        await dispatch(createTemplateThunk(payload)).unwrap();

        addToast("Template created!", {
          appearance: "success",
        });
        navigate("/templates");
      }
    } catch (err) {
      addToast((err as Error).message, { appearance: "error" });
    } finally {
      setLoading(false);
    }
  }, [name, templateType, relatedAnswers, language, components, background, hardCoverBackground, backCover, enablePriority, priority]);

  useEffect(() => {
    (async () => {
      await dispatch(getQuestionsThunk({ showAll: true }));
      await dispatch(getLanguagesThunk());
      await dispatch(getBooksThunk());
      if (params.id) await dispatch(getTemplateThunk({ id: params.id }));
    })();
    return () => {
      dispatch(templatesActions.clearFetchedTemplate());
    };
  }, []);

  useEffect(() => {
    if (fetchedTemplate) {
      const allAnswers: SelectOption<string>[] = questions.reduce<SelectOption<string>[]>((acc, group) => [...acc, ...group.options], []);
      resetName(fetchedTemplate.name);
      if (fetchedTemplate.background) {
        setBackground(fetchedTemplate.background.background_url.indexOf("http") > -1 ? fetchedTemplate.background.background_url : `${baseURL}${fetchedTemplate.background.background_url}`);
      }

      if (fetchedTemplate.hard_cover_background){
        setHardCoverBackground(fetchedTemplate.hard_cover_background.background_url.indexOf("http") > -1 ? fetchedTemplate.hard_cover_background.background_url : `${baseURL}${fetchedTemplate.hard_cover_background.background_url}`);
      }

      setLanguage(languages[0].options.find(lang => lang.value === fetchedTemplate.language.code)!);
      setBookTypes(fetchedTemplate.books.map(book => bookSlugs[0].options.find(bookSlug => bookSlug.value === book.bookSlug)!)!);
      setTemplateType(templateTypes.find(type => type.value === fetchedTemplate.template_type)!);
      setRelatedAnswers(fetchedTemplate.related_answers.map(answer => allAnswers.find(option => option.value === answer.id)!));
      setComponents(fetchedTemplate.elements.map(element => ({ id: element.element_properties.id, type: element.element_type.toUpperCase() as ComponentTypes, props: element.element_properties })));
      if (fetchedTemplate.priority) {
        setEnablePriority(true);
        setPriority(fetchedTemplate.priority);
      }
    }
  }, [fetchedTemplate]);

  useEffect(() => {
    if (templateType?.value === "cover")
      dispatch(getTemplatesThunk({ showAll: true, template_type: "back_cover" }));
  }, [templateType?.value]);

  useEffect(() => {
    if (params.id && fetchedTemplate && fetchedTemplate.back_cover && templateType?.value === "cover" && !backCover) setBackCover(templates[0].options.find(template => template.value === fetchedTemplate.back_cover!.id)!);
  }, [templates]);

  return (
    <div className={classes.main}>
      {preview && (
        <div className={classes.imagePreviewContainer}>
          <img alt="background preview" src={typeof preview === "string" ? preview : URL.createObjectURL(preview)} />
          <button className={classes.closeButton} onClick={() => setPreview(undefined)}>&#10006;</button>
        </div>
      )}
      <div className={classes.preview}>
        <div
          key={background?.toString()}
          ref={domTarget}
          className={classes.background}
          style={backgroundStyles}
        >
          {components.map((component) => {
            const Component = componentMap[component.type];

            return (
              <Component
                key={component.id}
                className={classes.component}
                deleteComponent={handleDeleteComponent}
                domTarget={domTarget}
                updateComponentProp={updateComponentProp}
                {...component.props}
              />
            );
          })}
        </div>
        <div className={classes.components}>
          <Button
            label="Add Text Component"
            onClick={() => addComponent("text")}
          />
          <Button
            label="Add Image Component"
            onClick={() => addComponent("image")}
          />
        </div>
      </div>
      <div className={classes.options}>
        <div className={classes.name}>
          <TextInput
            label="Name"
            placeholder="Enter name"
            value={name}
            onChange={setName}
          />
        </div>
        <div className={classes.background}>
          <div>
            <label htmlFor="background">Choose background</label>
            <input
              accept="image/*"
              id="background"
              type="file"
              onChange={handleBackgroundChange}
            />
          </div>
          {background && <img alt="hard cover" height={100} src={typeof background === "string" ? background : URL.createObjectURL(background)} onClick={() => setPreview(background)} />}
        </div>
        {templateType?.value !== "page" && (
          <div className={classes.background}>
            <div>
              <label htmlFor="hard_cover_background">Choose hard cover background</label>
              <input
                accept="image/*"
                id="hard_cover_background"
                type="file"
                onChange={handleHardCoverBackgroundChange}
              />
            </div>
            {hardCoverBackground && <img alt="hard cover" height={100} src={typeof hardCoverBackground === "string" ? hardCoverBackground : URL.createObjectURL(hardCoverBackground)} onClick={() => setPreview(hardCoverBackground)} />}
          </div>
        )}
        <div className={classes.priority}>
          <label htmlFor="priority">Choose template position (in the product)</label>
          <div className={classes.priorityInputs}>
            <Checkbox checked={enablePriority} color="primary" value={enablePriority} onChange={(_, checked) => setEnablePriority(checked)} />
            <InputNumber className={classes.numberInput} disabled={!enablePriority} max={40} min={-40} value={priority} onChange={num => setPriority(num || 0)} />
          </div>
        </div>
        <div className={classes.relatedQuestions}>
          <label htmlFor="relatedAnswers">Related Questions</label>
          <Select
            components={{ MultiValueLabel }}
            id="relatedAnswers"
            name="relatedAnswers"
            options={questions}
            placeholder="Select related answers"
            value={relatedAnswers}
            isMulti
            onChange={(value) => setRelatedAnswers(value)}
          />
        </div>
        <div className={classes.language}>
          <label htmlFor="language">Language</label>
          <Select
            id="language"
            name="language"
            options={languages}
            placeholder="Select language"
            value={language}
            onChange={setLanguage}
          />
        </div>
        <div className={classes.templateType}>
          <label htmlFor="bookTypes">Book Types</label>
          <Select
            id="bookTypes"
            name="bookTypes"
            options={bookSlugs}
            placeholder="Select corresponding book types"
            value={bookTypes}
            isMulti
            onChange={(value) => setBookTypes(value)}
          />
        </div>
        <div className={classes.templateType}>
          <label htmlFor="templateType">Template Type</label>
          <Select
            id="templateType"
            name="templateType"
            options={templateTypes}
            placeholder="Select template type"
            value={templateType}
            onChange={(value) => setTemplateType(value)}
          />
        </div>
        {templateType?.value === "cover" && (
          <div className={classes.templateType}>
            <label htmlFor="">Back cover</label>
            <Select
              id="backCover"
              name="backCover"
              options={templates}
              placeholder="Select back cover"
              value={backCover}
              onChange={value => setBackCover(value)}
            />
          </div>
        )}
        <div>
          <Button label={params.id ? "Save" : "Create"} onClick={handleSave} />
        </div>
      </div>
      {loading && (
        <div className={classes.loading}>
          <Spinner />
        </div>
      )}
    </div>
  );
};

export default CreateTemplate;
