import React, { useMemo, useState } from "react";
import Select, { GroupBase } from "react-select";
import { useSelector } from "react-redux";
import { Box, Typography, FormControlLabel, Switch, Button, TextField } from "@material-ui/core";
import { FormatAlignLeft, FormatAlignCenter, FormatAlignRight } from "@material-ui/icons";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";

import { Alignment, SelectOption } from "shared/lib/models";
import { RootState } from "store";
import TextInput from "../TextInput";
import { useStateForInput } from "shared/lib/hooks";
import { TextProps } from "./Text";

interface TextOptionsModalContentProps {
  changeable?: boolean | "false" | "true"
  uppercase?: boolean | "false" | "true"
  required?: boolean | "false" | "true"
  alignment?: Alignment
  relatedQuestion?: string
  textId?: string;
  placeholder?: string;
  onSave: (payload: Partial<TextProps>) => void
}

const TextOptionsModalContent: React.FC<TextOptionsModalContentProps> = ({ onSave, changeable: defaultChangeable, required: defaultRequired, alignment: defaultAlignment, relatedQuestion: defaultRelatedQuestion, uppercase: defaultUppercase, textId: defaultTextId, placeholder: defaultPlaceholder }) => {
  const questions = useSelector<RootState, GroupBase<SelectOption<string>>[]>(
    (state) => [{
      options: state.questions.questions.filter(question => ["text", "textarea"].indexOf(question.questionType) > -1).map(question => ({
        label: question.label,
        value: question.id
      }))
    }]
  );

  const [alignment, setAlignment] = useState<Alignment>(defaultAlignment || "left");
  const [changeable, setChangeable] = useState(typeof defaultChangeable === "string" ? defaultChangeable === "true" : defaultChangeable || false);
  const [required, setRequired] = useState(typeof defaultRequired === "string" ? defaultRequired === "true" : defaultRequired || false);
  const [uppercase, setUppercase] = useState(typeof defaultUppercase === "string" ? defaultUppercase === "true" : defaultUppercase || defaultUppercase || false);
  const [selectedQuestion, setSelectedQuestion] = useState<SelectOption<string> | null>(questions[0].options.find(option => option.value === defaultRelatedQuestion) || null);
  const [textId, setTextId] = useStateForInput(defaultTextId || "");
  const [placeholder, setPlaceholder] = useStateForInput(defaultPlaceholder || "");

  const changeableSwitchInput = useMemo(() => (
    <Switch
      checked={changeable}
      color="primary"
      disabled={required}
      name="checked"
      onChange={(e, value) => setChangeable(value)}
    />
  ), [changeable, required, setChangeable]);

  const requiredSwitchInput = useMemo(() => (
    <Switch
      checked={required}
      color="primary"
      name="required"
      onChange={(e, value) => {
        if (value === true) setChangeable(true);
        setRequired(value);
      }}
    />
  ), [required, setRequired]);

  const uppercaseSwitchInput = useMemo(() => (
    <Switch
      checked={uppercase}
      color="primary"
      name="uppercase"
      onChange={(e, value) => setUppercase(value)}
    />
  ), [uppercase, setUppercase]);

  return (
    <Box p={5} width="50vw">
      <Typography variant="h3">Options</Typography>
      <Box display="grid" gridGap={30} mt={3}>
        <FormControlLabel
          control={changeableSwitchInput}
          label="Changeable"
        />

        <FormControlLabel
          control={requiredSwitchInput}
          label="Required"
        />

        <FormControlLabel
          control={uppercaseSwitchInput}
          label="Uppercase"
        />

        <Box>
          <Typography>Alignment</Typography>
          <ToggleButtonGroup
            aria-label="text alignment"
            value={alignment}
            exclusive
            onChange={(event: React.MouseEvent<HTMLElement>, alignment: Alignment) => setAlignment(alignment)}
          >
            <ToggleButton aria-label="left aligned" value="left">
              <FormatAlignLeft />
            </ToggleButton>
            <ToggleButton aria-label="centered" value="center">
              <FormatAlignCenter />
            </ToggleButton>
            <ToggleButton aria-label="right aligned" value="right">
              <FormatAlignRight />
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>

        <Select
          menuPortalTarget={document.body}
          options={questions}
          placeholder="Related question"
          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
          value={selectedQuestion}
          onChange={value => setSelectedQuestion(value)}
        />

        <TextInput
          label="ID"
          placeholder="Enter ID"
          value={textId}
          onChange={setTextId}
        />

        {/*<textarea cols={30} id="placeholder" name="placeholder" rows={10} onChange={setPlaceholder}>{placeholder}</textarea>*/}
        <TextField id="placeholder" label="Placeholder" name="placeholder" rows={2} value={placeholder} variant="outlined" multiline onChange={setPlaceholder} />

        <Button
          color="primary"
          size="large"
          variant="contained"
          onClick={() => onSave({ changeable, required, alignment, relatedQuestion: selectedQuestion?.value, uppercase, textId, placeholder: placeholder.replaceAll(/\n/g, "<br>") })}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default TextOptionsModalContent;
