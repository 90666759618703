import axiosInstance from "config/axiosInstance";
import paths from "./paths";
import { IUpdateSettingsPayload } from "shared/lib/models";

export default {
  getSettings: async (): Promise<IUpdateSettingsPayload> => {
    return await axiosInstance.get<IUpdateSettingsPayload, IUpdateSettingsPayload>(paths.settings.get);
  },
  updateSettings: async (payload: IUpdateSettingsPayload): Promise<IUpdateSettingsPayload> => {
    return await axiosInstance.put<IUpdateSettingsPayload, IUpdateSettingsPayload>(paths.settings.put, payload);
  }
};
