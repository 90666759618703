import React, { useCallback, useEffect, useState } from "react";
import InputNumber from "rc-input-number";
import DeleteIcon from "@material-ui/icons/Delete";
import Select, { Options } from "react-select";
import { useSelector } from "react-redux";
import { isFulfilled } from "@reduxjs/toolkit";
import { useToasts } from "react-toast-notifications";
import { useNavigate, useParams } from "react-router-dom";
import { Button, FormControlLabel, Paper, Switch, TextField } from "@material-ui/core";

import classes from "./CreateQuestion.module.scss";
import Spinner from "components/Spinner";
import { RootState } from "store";
import { ICreateQuestionPayload, IQuestion, SelectOption, QuestionType } from "shared/lib/models";
import { questionSchema } from "schemas/question.schema";
import { useAppDispatch } from "hooks";
import { useStateForInput } from "shared/lib/hooks";
import { getBooksThunk } from "redux/books/thunks/getBooks.thunk";
import { createQuestionThunk } from "redux/questions/thunks/createQuestion.thunk";
import { getQuestionThunk } from "redux/questions/thunks/getQuestion.thunk";
import { updateQuestionThunk } from "redux/questions/thunks/updateQuestion.thunk";
import { questionsActions } from "redux/questions/questions.slice";

const questionTypes = [
  { label: "Text", value: QuestionType.TEXT },
  { label: "Textarea", value: QuestionType.TEXTAREA },
  { label: "Checkbox", value: QuestionType.CHECKBOX },
  { label: "Choice", value: QuestionType.CHOICE },
  { label: "Radio", value: QuestionType.RADIO },
  { label: "Date", value: QuestionType.DATE },
  { label: "File", value: QuestionType.FILE },
  { label: "Manufacture Date", value: QuestionType.EVENT_DATE }
];

const CreateQuestion: React.FC = () => {
  const [label, setLabel, resetLabel] = useStateForInput("");
  const [description, setDescription, resetDescription] = useStateForInput("");
  const [questionType, setQuestionType] = useState<SelectOption<QuestionType>>(questionTypes[0]);
  const [bookSlugs, setBookSlugs] = useState<Options<SelectOption<string>>>([]);
  const [priority, setPriority] = useState<number>(1);
  const [required, setRequired] = useState(false);
  const [answers, setAnswers] = useState<string[]>([]);
  const [answer, setAnswer, resetAnswer] = useStateForInput("");
  const [availableSideAnswer, setAvailableSideAnswer] = useState(false);
  const [loading, setLoading] = useState(false);

  const books = useSelector<RootState, SelectOption<string>[]>(state => state.books.books.map(book => ({ label: book.bookName, value: book.bookSlug })));
  const fetchedQuestion = useSelector<RootState, IQuestion | undefined>(state => state.questions.fetchedQuestion);

  const params = useParams<{ id?: string }>();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { addToast } = useToasts();

  const addAnswer = useCallback(() => {
    if (answers.indexOf(answer) > -1) {
      addToast("There is such answer. Please try something different", {
        appearance: "warning"
      });
      return;
    }

    setAnswers([...answers, answer]);
    resetAnswer("");
  }, [answer, answers]);

  const removeAnswer = useCallback(answer => {
    setAnswers(answers.filter(a => a !== answer));
  }, [answers]);

  const saveQuestion = useCallback(async () => {
    setLoading(true);
    try {
      const payload: ICreateQuestionPayload = {
        label,
        description,
        questionType: questionType?.value,
        bookSlugs: bookSlugs.map(slug => slug.value),
        required,
        availableSideAnswer,
        availableAnswers: answers,
        question_priority: priority
      };
      await questionSchema.validate(payload);

      if (params.id) {
        const res = await dispatch(updateQuestionThunk({ id: params.id, ...payload }));

        if (isFulfilled(updateQuestionThunk)(res)) {
          addToast("Question updated!", {
            appearance: "success",
          });
          navigate("/questions");
        }
      } else {
        const res = await dispatch(createQuestionThunk(payload));

        if (isFulfilled(createQuestionThunk)(res)) {
          addToast("Question created!", {
            appearance: "success",
          });
          navigate("/questions");
        }
      }
    } catch (err) {
      addToast((err as Error).message, { appearance: "error" });
    } finally {
      setLoading(false);
    }
  }, [label, description, questionType, bookSlugs, required, availableSideAnswer, answers, priority, params.id]);

  useEffect(() => {
    dispatch(getBooksThunk());
  }, []);

  useEffect(() => {
    if (params.id) {
      dispatch(getQuestionThunk({ id: params.id }));
    }

    return () => {
      dispatch(questionsActions.clearFetchedQuestion());
    };
  }, [params.id]);

  useEffect(() => {
    if (fetchedQuestion) {
      resetLabel(fetchedQuestion.label);
      resetDescription(fetchedQuestion.description);
      setQuestionType(questionTypes.find(qt => qt.value === fetchedQuestion.questionType)!);
      setAnswers(fetchedQuestion.availableAnswers ? fetchedQuestion.availableAnswers.map(answer => answer.answer) : []);
      setRequired(fetchedQuestion.required);
      setPriority(fetchedQuestion.question_priority);
      setAvailableSideAnswer(fetchedQuestion.availableSideAnswer);
      setBookSlugs(fetchedQuestion.bookSlugs.map(slug => books.find(book => book.value === slug)!));
    }
  }, [fetchedQuestion]);

  return (
    <div className={classes.container}>
      <TextField id="label" label="Label" value={label} variant="outlined" onChange={setLabel} />
      <TextField id="description" label="Description" value={description} variant="outlined" onChange={setDescription} />
      <Select options={questionTypes} placeholder="Question type" value={questionType} onChange={value => setQuestionType(value!)} />
      <Select options={books} placeholder="Book" value={bookSlugs} isMulti onChange={value => setBookSlugs(value)} />
      <div>
        <label htmlFor="">Priority&nbsp;</label>
        <InputNumber min={1} value={priority} onChange={num => setPriority(num || 0)} />
      </div>
      <FormControlLabel control={<Switch checked={required} id="required" name="required" onChange={e => setRequired(e.target.checked)} />} label="Required" />
      {[QuestionType.TEXT, QuestionType.TEXTAREA, QuestionType.CHOICE].indexOf(questionType?.value) > -1 && (
        <FormControlLabel control={<Switch checked={availableSideAnswer} id="availableSideAnswer" name="availableSideAnswer" onChange={e => setAvailableSideAnswer(e.target.checked)} />} label="Available Side Answer?" />
      )}
      {[QuestionType.CHOICE, QuestionType.CHECKBOX, QuestionType.RADIO].indexOf(questionType?.value) > -1 && (
        <div className={classes.answersContainer}>
          <div className={classes.answers}>
            {answers.map(answer => (
              <Paper key={answer} className={classes.answer} elevation={5}>{answer} <DeleteIcon color="secondary" cursor="pointer" onClick={() => removeAnswer(answer)} /></Paper>
            ))}
          </div>
          <div className={classes.answerControls}>
            <TextField id="answer" label="Answer" value={answer} variant="outlined" onChange={setAnswer} />
            <Button color="primary" size="large" variant="contained" onClick={addAnswer}>Add</Button>
          </div>
        </div>
      )}
      <Button color="primary" size="large" variant="contained" onClick={saveQuestion}>{params.id ? "Update" : "Create"}</Button>
      {loading && (
        <div className={classes.loading}>
          <Spinner />
        </div>
      )}
    </div>
  );
};

export default CreateQuestion;
