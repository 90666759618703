import React, { useCallback, useEffect, useState } from "react";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { Box, Button, Grid, Paper, TextField, Typography } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

import { useAppDispatch } from "hooks";
import { updateSettingsThunk } from "redux/settings/thunks/updateSettings.thunk";
import { getSettingsThunk } from "redux/settings/thunks/getSettings.thunk";
import { ISettingsState } from "models";
import { RootState } from "store";

const Config: React.FC = () => {
  const weekdaysMap = dayjs.weekdays(true).map((value, idx) => ({ value, idx }));

  const settings = useSelector<RootState, ISettingsState>(state => state.settings);

  const [weekday, setWeekday] = useState<typeof weekdaysMap[0]>(weekdaysMap[0]);
  const [productionDays, setProductionDays] = useState(0);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getSettingsThunk());
  }, []);

  useEffect(() => {
    if (settings && settings.deliveryDay && settings.numberOfDaysAfterDelivery) {
      setWeekday(weekdaysMap.find(weekday => weekday.value === settings.deliveryDay)!);
      setProductionDays(settings.numberOfDaysAfterDelivery);
    }
  }, [settings]);

  const saveManufactureDates = useCallback(() => {
    dispatch(updateSettingsThunk({ delivery_day: weekday.value, number_of_days_after_delivery: productionDays }));
  }, [weekday, productionDays]);

  return (
    <Box padding={5}>
      <Box marginBottom={4}>
        <Typography variant="h3">Configuration</Typography>
      </Box>
      <Grid spacing={4} container>
        <Grid item>
          <Paper elevation={12} style={{ padding: 16 }} >
            <Box display="flex" flexDirection="column" gridGap={32}>
              <Typography variant="h5">Manufacture Dates</Typography>
              <Box alignItems="center" display="flex" gridGap={32}>
                <Typography title="The day of the week when the order is sent." variant="body1">Order Day</Typography>
                <Autocomplete getOptionLabel={option => option.value} options={weekdaysMap} renderInput={params => <TextField {...params} label="Weekday" variant="outlined" />} style={{ minWidth: 200 }} value={weekday} disableClearable onChange={(event, option) => setWeekday(option)} />
              </Box>
              <Box alignItems="center" display="flex" gridGap={32}>
                <Typography title="The number of days for which the copier will make the book." variant="body1">Time for production</Typography>
                <TextField defaultValue={0} label="Number of days" type="number" value={productionDays} variant="outlined" onChange={e => setProductionDays(+e.target.value)} />
              </Box>
              <Box display="flex" justifyContent="flex-end">
                <Button color="primary" variant="contained" onClick={saveManufactureDates}>Save</Button>
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Config;