import { createAsyncThunk } from "@reduxjs/toolkit";

import templatesApi from "api/templatesApi";
import { IElement } from "models";
import { ICreateTemplatePayload, ITemplate } from "shared/lib/models";
import { prefix } from "./config";

export const createTemplateThunk = createAsyncThunk<ITemplate<IElement>, ICreateTemplatePayload>(`${prefix} Create Template`, async (payload, { rejectWithValue }) => {
  try {
    return await templatesApi.createTemplate(payload);
  } catch(err) {
    console.log(err);
    return rejectWithValue(err);
  }
});
