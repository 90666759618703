import { createSlice } from "@reduxjs/toolkit";
import { productsInitialState } from "./products.initialState";
import { productsExtraReducers } from "./products.extraReducers";

export const productsSlice = createSlice({
  name: "products",
  initialState: productsInitialState,
  reducers: {},
  extraReducers: productsExtraReducers
});

export const { actions: productsActions } = productsSlice;
