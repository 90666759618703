import React, { CSSProperties, useCallback, useMemo } from "react";

import { Alignment } from "shared/lib/models";
import { map } from "shared/lib/utils";
import { height } from "shared/lib/utils/bookAspectRatio";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import classes from "./Text.module.scss";
import { DefaultComponentProps } from "../utils";

export type TextProps = {
  id: string;
  left: string;
  top: string;
  value?: string
  color?: string
  fontFamily?: string
  fontSize?: string
  changeable?: string
  uppercase?: string
  required?: string
  alignment?: Alignment
  maxWidth?: string
  textId?: string;
  placeholder?: string;
}

export type TextComponentProps = DefaultComponentProps & TextProps & {
  updateComponentProp: (
    id: string,
    key: keyof TextProps | Record<string, unknown>,
    value?: unknown
  ) => void;
}

const Text: React.FC<TextComponentProps> = ({
  domTarget,
  id,
  updateComponentProp,
  answer,
  onClick: pushClick,
  component,
  preview,
  ...props
}) => {
  const style: CSSProperties = {
    fontSize: map(Number(props.fontSize), 0, height, 0, domTarget?.clientHeight ?? height / 2),
    lineHeight: map(Number(props.fontSize), 0, height, 0, domTarget?.clientHeight ?? height / 2) + "px",
    fontFamily: props.fontFamily,
    color: props.color,
    textAlign: props.alignment,
    textTransform: props.uppercase === "true" ? "uppercase" : undefined,
    minHeight: map(Number(props.fontSize), 0, height, 0, domTarget?.clientHeight ?? height / 2)
  };

  const text = useMemo(() => {
    if (answer) {
      const [answerText] = (answer.answer as string[]);

      if (props.value && props.value !== answerText) return props.value!;

      return answerText;
    }

    return props.value!;
  }, [props.value, props.placeholder]);

  const handleBlur = useCallback(e => {
    if (props.changeable == "false" || !props.changeable) return;

    updateComponentProp(id, "value", e.currentTarget.innerHTML!);
  }, []);

  const handleFocus = useCallback(e => {
    const selection = window.getSelection();
    const range = document.createRange();
    selection?.removeAllRanges();
    range.selectNodeContents(e.currentTarget);
    range.collapse(false);
    selection?.addRange(range);
    e.currentTarget.focus();
  }, []);

  const handleClick = useCallback((e) => {
    props.changeable == "true" && pushClick ? pushClick({
      ...component,
      element_properties: { ...component.element_properties, ...props }
    }) : null;
  }, []);

  return (
    <div
      className={`${classes.container} ${preview && classes.preview}`}
      style={{
        top: props.top + "%",
        left: props.left + "%",
        position: "absolute",
        width: props.maxWidth + "%",
        ...style
      }}
    >
      <span
        dangerouslySetInnerHTML={{ __html: text }}
        className={`${classes.text} ${props.required === "true" ? classes.required : ""}`}
        contentEditable={props.changeable == "true"}
        placeholder={props.placeholder}
        spellCheck={false}
        style={style}
        suppressContentEditableWarning
        onBlur={handleBlur}
        onClick={handleClick}
        onFocus={handleFocus}
      />
      <div className={classes.border} />
      {!preview && props.changeable === "true" && (
        <div className={classes.fontSize}>
          <div
            className={classes.button}
            onClick={() => updateComponentProp(id, "fontSize", Number(props.fontSize) + 5)}
          >
            <svg fill="none" height="19" viewBox="0 0 19 19" width="19" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M19 10.8571H10.8571V19H8.14286V10.8571H0V8.14286H8.14286V0H10.8571V8.14286H19V10.8571Z"
                fill="white"
              />
            </svg>
          </div>
          <div
            className={classes.button}
            onClick={() => updateComponentProp(id, "fontSize", Number(props.fontSize) - 5)}
          >
            <svg fill="none" height="3" viewBox="0 0 22 3" width="22" xmlns="http://www.w3.org/2000/svg">
              <path d="M22 3H0V0H22V3Z" fill="white" />
            </svg>
          </div>
          <div className={classes.button} onClick={() => updateComponentProp(id, "top", Number(props.top) - 1)}>
            <svg fill="none" height="23" viewBox="0 0 23 23" width="23" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M5.58667 9.20557L11.0516 14.7435L16.5895 9.27853"
                stroke="#F2F2F2"
                strokeLinecap="round"
                strokeWidth={3}
                transform="translate(22 22) rotate(180)"
              />
            </svg>
          </div>
          <div className={classes.button} onClick={() => updateComponentProp(id, "top", Number(props.top) + 1)}>
            <svg fill="none" height="23" viewBox="0 0 23 23" width="23" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M5.58667 9.20557L11.0516 14.7435L16.5895 9.27853"
                stroke="#F2F2F2"
                strokeLinecap="round"
                strokeWidth={3}
              />
            </svg>
          </div>
        </div>
      )}
    </div>
  );
};

export default Text;
