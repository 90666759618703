import * as TemplateComponents from "components/TemplateComponents";
import { ImageProps } from "components/TemplateComponents/Image";
import { TextProps } from "components/TemplateComponents/Text";

export enum ComponentTypes {
  TEXT = "TEXT",
  IMAGE = "IMAGE"
}

export type Component<T> = {
  id: string;
  type: ComponentTypes
  props: T
}

export const componentMap = {
  [ComponentTypes.IMAGE]: TemplateComponents.Image,
  [ComponentTypes.TEXT]: TemplateComponents.Text
};

export const componentTypes = {
  text: (): Component<TextProps> => {
    const id = "_" + Math.random().toString(36).substr(2, 9);
    return {
      id,
      type: ComponentTypes.TEXT,
      props: {
        id,
        left: 0,
        top: 0,
        value: "text",
        color: "black",
        fontFamily: "Josefin Sans",
        fontSize: 16,
        changeable: false
      }
    };
  },
  image: (): Component<ImageProps> => {
    const id = "_" + Math.random().toString(36).substr(2, 9);
    return {
      id,
      type: ComponentTypes.IMAGE,
      props: {
        id,
        left: 0,
        top: 0,
        uri: "",
        width: 200,
        height: 200,
        rotation: 0,
      }
    };
  }
};
