import { createAsyncThunk } from "@reduxjs/toolkit";

import templatesApi from "api/templatesApi";
import { IDeleteTemplatePayload } from "shared/lib/models";
import { prefix } from "./config";
import { getTemplatesThunk } from "./getTemplates.thunk";

export const deleteTemplateThunk = createAsyncThunk<undefined, IDeleteTemplatePayload>(`${prefix} Delete Template`, async (payload, { rejectWithValue, dispatch }) => {
  try {
    await templatesApi.deleteTemplate(payload);
    await dispatch(getTemplatesThunk(payload));
    return;
  } catch(err) {
    console.log(err);
    return rejectWithValue(err);
  }
});
