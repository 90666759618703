import React, {
  forwardRef,
  SetStateAction,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { debounce } from "debounce";
import { useStateWithHistory } from "react-use";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import classes from "./TemplatePersonalization.module.scss";
import { useForceUpdate } from "shared/lib/hooks";
import {
  Answer,
  ComponentTypes,
  CoverType, IProduct,
  ITemplate,
} from "shared/lib/models";
import { componentMap } from "./utils";

import "external-svg-loader";

export type TemplatePersonalizationRef = {
  getTemplate: () => ITemplate<any>;
  resetTemplate: (template?: SetStateAction<ITemplate<any>>) => void;
  undoTemplate: () => void;
  redoTemplate: () => void;
  getDomTarget: () => HTMLDivElement | null;
};

export type TemplatePersonalizationProps = {
  product?: IProduct;
  template: ITemplate<any>;
  preview?: boolean;
  big?: boolean;
  answers: Answer[];
  coverType: CoverType | null;
  baseURL: string;
  onComponentClick?: (component: any) => void;
  ref?: React.Ref<TemplatePersonalizationRef>;
};

const TemplatePersonalization: React.FC<TemplatePersonalizationProps> =
  forwardRef(function TemplatePersonalization(
    {
      template: defaultTemplate,
      product,
      onComponentClick,
      answers,
      coverType,
      baseURL,
      preview,
      big
    },
    ref
  ) {
    const [template, setTemplate, templateHistory] = useStateWithHistory(
      defaultTemplate,
      30
    );
    const [domTarget, setDomTarget] = useState<HTMLDivElement | null>(null);

    const forceUpdate = useForceUpdate();

    const updateComponentProp = useCallback((id, key, value) => {
      setTemplate((previousTemplate) => {
        return {
          ...previousTemplate,
          elements: [
            ...previousTemplate.elements.map((comp) =>
              comp.element_properties.id === id
                ? {
                  ...comp,
                  element_properties: {
                    ...comp.element_properties,
                    ...(typeof key === "object" ? key : { [key]: value }),
                  },
                }
                : comp
            ),
          ],
        };
      });
    }, []);

    const renderElement = useCallback(
      (component: any) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const Component = componentMap[component.element_type.toUpperCase()];
        const answer =
          component.element_properties.relatedQuestion &&
          answers.find(
            (answer) =>
              answer.questionId === component.element_properties.relatedQuestion
          );
        let displayImage = null;

        if (component.element_type.toUpperCase() === ComponentTypes.IMAGE && answer && product) {
          const pagesWithImage = product.pages
            .filter(page => !!page.elements.find(el => el.element_properties.relatedQuestion === answer.questionId));

          const attachmentImageIndexForComponent = pagesWithImage.findIndex(page => page.page_id === template.page_id);

          if (answer.attachmentPath && answer.attachmentPath[attachmentImageIndexForComponent]) {
            displayImage = answer.attachmentPath[attachmentImageIndexForComponent];
          } else {
            displayImage = component.image_url;
          }
        }

        if (!Component) return;

        return (
          <Component
            key={JSON.stringify(component.element_properties)}
            answer={answer}
            baseURL={baseURL}
            component={component}
            displayImage={displayImage}
            domTarget={domTarget}
            preview={preview}
            updateComponentProp={updateComponentProp}
            onClick={onComponentClick}
            {...component.element_properties}
          />
        );
      },
      [answers, updateComponentProp, onComponentClick, domTarget, preview, product, template]
    );

    useImperativeHandle<TemplatePersonalizationRef, TemplatePersonalizationRef>(
      ref,
      () => ({
        getTemplate: () => template,
        resetTemplate: (template) => {
          setTemplate(template || defaultTemplate);
        },
        undoTemplate: () => {
          templateHistory.back(1);
        },
        redoTemplate: () => {
          templateHistory.forward(1);
        },
        getDomTarget: () => domTarget,
      }),
      [template, domTarget]
    );

    useEffect(() => {
      const update = debounce(forceUpdate, 200);

      window.addEventListener("resize", update);
      return () => window.removeEventListener("resize", update);
    }, []);

    const templateTypeSpecificStyles = useMemo(() => {
      const isHardCover =
        ["cover", "back_cover"].indexOf(template.template_type) > -1 &&
        coverType &&
        coverType === "hard";
      const hardCoverPadding = 55;

      if (template.template_type === "page") {
        return {
          padding: 5,
        };
      } else if (template.template_type === "cover") {
        if (isHardCover && preview) {
          return {
            paddingLeft: 10,
            paddingRight: hardCoverPadding,
            paddingTop: hardCoverPadding,
            paddingBottom: hardCoverPadding,
          };
        }

        return {
          paddingLeft: 10,
        };
      } else if (template.template_type === "back_cover") {
        if (isHardCover && preview) {
          return {
            paddingRight: 10,
            paddingLeft: hardCoverPadding,
            paddingTop: hardCoverPadding,
            paddingBottom: hardCoverPadding,
          };
        }

        return {
          paddingRight: 10,
        };
      }
    }, [template.template_type, coverType]);

    const background = useMemo(() => {
      if (template.template_type === "cover" && coverType === "hard") {
        return template.hard_cover_background?.background_url;
      }

      return template.background.background_url;
    }, [template, coverType]);

    return (
      <div
        className={`
          ${classes.aspectRatio}
          ${coverType === "hard" && classes.hardCover}
          ${big && classes.big}
        `}
      >
        <div
          ref={(ref) => setDomTarget(ref)}
          className={`
            ${preview && classes.preview}
          `}
        >
          {background && background.indexOf(".svg") > -1 ? (
            <svg
              className={classes.background}
              data-src={encodeURI(background!)}
              height="100%"
              width="100%"
            />
          ) : (
            <img
              alt="background"
              height="100%"
              src={encodeURI(background!)}
              style={{
                objectFit: "cover",
                width: "100%",
                padding: 0,
                margin: "0 auto",
                boxSizing: "border-box",
                backgroundColor: "grey"
              }}
              width="100%"
            />
          )}
          <div
            style={{ position: "absolute", inset: 0, transition: "padding 1s", ...templateTypeSpecificStyles }}
          >
            <div
              className={classes.cover}
            >
              {template.elements.map(renderElement)}
            </div>
          </div>
        </div>
      </div>
    );
  });

export default TemplatePersonalization;
