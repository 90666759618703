import React, { useEffect, useRef, useState } from "react";
import SettingsIcon from "@material-ui/icons/Settings";
import { animated, to, useSpring } from "react-spring";
import { useGesture } from "@use-gesture/react";

import classes from "./Text.module.scss";
import Modal from "components/Modal";
import TextOptionsModalContent from "./TextOptionsModalContent";
import { useStateForInput } from "shared/lib/hooks";
import { Alignment } from "shared/lib/models";
import { map } from "shared/lib/utils";
import { height } from "shared/lib/utils/bookAspectRatio";

export type TextProps = {
  id: string;
  left: number;
  top: number;
  value?: string
  color?: string
  fontFamily?: string
  fontSize?: number
  changeable?: boolean | "false" | "true"
  uppercase?: boolean | "false" | "true"
  required?: boolean | "false" | "true"
  alignment?: Alignment
  maxWidth?: number
  relatedQuestion?: string
  textId?: string;
  placeholder?: string;
}

type TextComponentProps = TextProps & {
  id: string
  className: string
  domTarget: React.RefObject<HTMLDivElement>
  updateComponentProp: (
    id: string,
    key: keyof TextProps | Partial<TextProps>,
    value?: unknown
  ) => void;
  deleteComponent: (id: string) => void
}

const Text: React.FC<TextComponentProps> = ({ className, id, updateComponentProp, deleteComponent, domTarget, ...props }) => {
  const [value, setValue] = useState(props.value);
  const [fontSize, setFontSize] = useStateForInput(props.fontSize?.toString() ?? "16");
  const [maxWidth, setMaxWidth] = useStateForInput(props.maxWidth?.toString() ?? "");
  const [fontFamily, setFontFamily] = useState(props.fontFamily);
  const [color, setColor] = useStateForInput(props?.color ?? "");
  const [openMenu, setOpenMenu] = useState(false);
  const [openTextModal, setOpenTextModal] = useState(false);

  const divRef = useRef<HTMLDivElement | null>(null);

  const [{ top, left }, api] = useSpring(
    () => ({
      top: +props.top || 0,
      left: +props.left || 0,
    })
  );

  useEffect(() => {
    updateComponentProp(id, "fontSize", fontSize);
  }, [fontSize]);

  useEffect(() => {
    updateComponentProp(id, "fontFamily", fontFamily);
  }, [fontFamily]);

  useEffect(() => {
    updateComponentProp(id, "color", color);
  }, [color]);

  useEffect(() => {
    updateComponentProp(id, "value", value);
  }, [value]);

  useEffect(() => {
    updateComponentProp(id, "maxWidth", maxWidth);
  }, [maxWidth]);

  useGesture(
    {
      onDrag: ({ offset: [x, y] }) => {
        if (domTarget.current && domTarget.current.clientHeight && domTarget.current.clientWidth) {
          api.start({ left: x / domTarget.current.clientWidth * 100, top: y / domTarget.current.clientHeight * 100 });
        }
      },
      onDragEnd: ({ offset: [x, y] }) => {
        if (domTarget.current && domTarget.current.clientHeight && domTarget.current.clientWidth) {
          updateComponentProp(id, { left: x / domTarget.current.clientWidth * 100, top: y / domTarget.current.clientHeight * 100 });
        }
      }
    },
    {
      drag: {
        bounds: domTarget.current!,
        from: () => [left.get() / 100 * (domTarget.current?.clientWidth || 0), top.get() / 100 * (domTarget.current?.clientHeight || 0)]
      },
      target: divRef,
      eventOptions: { passive: false },
    }
  );

  return (
    <>
      <animated.div
        ref={divRef}
        style={{ top: to([top], top => top + "%"), left: to([left], left => left + "%"), position: "absolute", width: maxWidth + "%" }}
        tabIndex={0}
        onBlur={e => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
          if (e.currentTarget.contains(e.relatedTarget) || e.currentTarget.textContent === "") return;
          setOpenMenu(false);
        }}
      >
        <div style={{ position: "absolute", top: "-30px", display: "flex", opacity: openMenu ? 1 : 0, zIndex: 12 }}>
          {value?.trim() === "" && <input type="text" value={value} onChange={e => setValue(e.target.value)} />}
          <input placeholder="Font Size" type="number" value={fontSize} width={20} onChange={setFontSize} />
          <input max={100} min={0} placeholder="Width (in %)" type="number" value={maxWidth} width={20} onChange={setMaxWidth} />
          <input placeholder="Color" type="color" value={color} onChange={setColor} />
          <select className={classes.fontFamily} id="fontFamily" name="fontFamily" value={fontFamily} onChange={e => setFontFamily(e.target.value)}>
            <option label="Select font" value="" disabled />
            <option label="Nickainley" value="Nickainley" />
            <option label="Peace Sans" value="Peace Sans" />
            <option label="Sensei" value="Sensei" />
            <option label="Amatic SC" value="Amatic SC" />
            <option label="Alice" value="Alice" />
            <option label="VollkornSC" value="VollkornSC" />
            <option label="Oswald" value="Oswald" />
            <option label="Andika" value="Andika" />
            <option label="Pattaya" value="Pattaya" />
          </select>
          <SettingsIcon color="action" onClick={() => setOpenTextModal(true)} />
          <button onClick={() => deleteComponent(id)}>delete</button>
        </div>
        <div
          className={className}
          dangerouslySetInnerHTML={{ __html: props.relatedQuestion ? props.placeholder || "" : value || props.placeholder || "" }}
          spellCheck={false}
          style={{
            fontSize: map(Number(fontSize), 0, height, 0, domTarget.current?.clientHeight ?? 0),
            fontFamily: fontFamily,
            color,
            textAlign: props.alignment,
            textTransform: props.uppercase === "true" || props.uppercase === true ? "uppercase" : undefined,
            width: "100%"
          }}
          contentEditable
          suppressContentEditableWarning
          onBlur={e => {
            if (e.currentTarget.innerHTML === "" && props.placeholder) {
              e.currentTarget.innerHTML = props.placeholder;
            } else if (!props.relatedQuestion) {
              setValue(e.currentTarget.innerHTML!);
            } else {
              updateComponentProp(id, "placeholder", e.currentTarget.innerHTML!);
            }
          }}
          onClick={() => setOpenMenu(true)}
          onFocus={e => {
            if (e.currentTarget.innerHTML === props.placeholder && e.currentTarget.innerHTML !== "" && !props.relatedQuestion) {
              e.currentTarget.innerHTML = "";
            }
          }}
        />
      </animated.div>
      <Modal isOpen={openTextModal} onClose={() => setOpenTextModal(false)}>
        <TextOptionsModalContent
          alignment={props.alignment}
          changeable={props.changeable}
          placeholder={props.placeholder}
          relatedQuestion={props.relatedQuestion}
          required={props.required}
          textId={props.textId}
          onSave={({ changeable, relatedQuestion, required, alignment, uppercase, textId, placeholder }) => {
            setOpenTextModal(false);

            const updatePayload: Parameters<typeof updateComponentProp>[1] = {};

            if (changeable !== null && changeable !== undefined) updatePayload.changeable = changeable;
            if (uppercase !== null && uppercase !== undefined) updatePayload.uppercase = uppercase;
            if (required !== null && required !== undefined) updatePayload.required = required;
            if (alignment !== null && alignment !== undefined) updatePayload.alignment = alignment;
            if (relatedQuestion !== null && relatedQuestion !== undefined) {
              updatePayload.relatedQuestion = relatedQuestion;
              updatePayload.placeholder = value;
              updatePayload.value = "";
            }
            if (textId) updatePayload.textId = textId;
            if (placeholder) updatePayload.placeholder = placeholder;

            updateComponentProp(id, updatePayload);
          }}
        />
      </Modal>
    </>
  );
};

export default Text;
