import React, { useState } from "react";
import Select, { GroupBase } from "react-select";
import { Box, Button, Slider, TextField, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";

import { RootState } from "store";
import { SelectOption } from "shared/lib/models";

type ImageSelectModalContentProps = {
  relatedQuestion?: string
  rotation?: number | number[]
  onSave: (options: { image?: string | File, relatedQuestion?: string | null, rotation?: number | number[] }) => void;
};

const ImageSelectModalContent: React.FC<ImageSelectModalContentProps> = ({
  relatedQuestion: defaultRelatedQuestion,
  rotation: defaultRotation,
  onSave,
}) => {
  const questions = useSelector<RootState, GroupBase<SelectOption<string>>[]>(
    (state) => [{
      options: state.questions.questions.filter(question => question.questionType === "file").map(question => ({
        label: question.label,
        value: question.id
      }))
    }]
  );

  const [rotation, setRotation] = useState<number | number[]>(defaultRotation || 0);
  const [currentImage, setCurrentImage] = useState<string | File>("");
  const [selectedQuestion, setSelectedQuestion] = useState<SelectOption<string> | null>(questions[0].options.find(option => option.value === defaultRelatedQuestion) || null);

  return (
    <Box p={5} width="50vw">
      <Typography variant="h3">Options</Typography>
      <Box display="grid" gridGap={30} mt={3}>
        <Select
          menuPortalTarget={document.body}
          options={questions}
          placeholder="Related question"
          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
          value={selectedQuestion}
          onChange={value => setSelectedQuestion(value)}
        />

        <Box>
          <Typography>Rotation</Typography>
          <Slider
            max={360}
            min={-360}
            step={10}
            value={rotation}
            valueLabelDisplay="auto"
            onChange={(e, value) => setRotation(value)}
          />
        </Box>

        <TextField id="uri" label="URI" variant="outlined" onChange={e => setCurrentImage(e.target.value)} />

        <div>
          <input
            accept="image/*"
            type="file"
            onChange={(e) => setCurrentImage(e.target.files![0])}
          />
        </div>

        <Button color="primary" size="large" variant="contained" onClick={() => onSave({ image: currentImage, relatedQuestion: selectedQuestion?.value, rotation })}>Save</Button>
      </Box>
    </Box>
  );
};

export default ImageSelectModalContent;
