import { ChangeEvent, useCallback, useState } from "react";

export const useStateForInput = (initialValue: string): [string, (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void, (value: string) => void] => {
  const [value, setValue] = useState(initialValue);

  const setter = useCallback((e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setValue(e.target.value);
  }, []);

  const resetter = useCallback((value: string) => {
    setValue(value || initialValue);
  }, []);

  return [value, setter, resetter];
};
