import React, { useCallback, useEffect, useState } from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import { Button, FormControl, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Grid, InputLabel, Select, MenuItem } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import classes from "./ListQuestions.module.scss";
import { RootState } from "store";
import { IBook, IQuestion } from "shared/lib/models";
import { useAppDispatch } from "hooks";
import { getQuestionsThunk } from "redux/questions/thunks/getQuestions.thunk";
import { deleteQuestionThunk } from "redux/questions/thunks/deleteQuestion.thunk";
import { getBooksThunk } from "redux/books/thunks/getBooks.thunk";

const ListQuestions: React.FC = () => {
  const [bookType, setBookType] = useState("");

  const questions = useSelector<RootState, IQuestion[]>(state => state.questions.questions);
  const bookTypes = useSelector<RootState, IBook[]>(state => state.books.books);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleDeleteQuestion = useCallback(questionId => {
    dispatch(deleteQuestionThunk({ questionId, showAll: true, book_slug: bookType }));
  }, [bookType]);

  const handleBookTypeChange = useCallback(e => setBookType(e.target.value), []);

  useEffect(() => {
    dispatch(getQuestionsThunk({ showAll: true, book_slug: bookType || undefined }));
  }, [bookType]);

  useEffect(() => {
    dispatch(getBooksThunk());
  }, []);

  return (
    <div className={classes.container}>
      <Button className={classes.newButton} color="primary" variant="contained" onClick={() => navigate("create")}>New</Button>
      <Grid spacing={3} container>
        <Grid xs={4} item>
          <FormControl variant="outlined" fullWidth>
            <InputLabel>Book Type</InputLabel>
            <Select id="bookType" label="Book Type" value={bookType} onChange={handleBookTypeChange}>
              <MenuItem value="">None</MenuItem>
              {bookTypes.map(type => <MenuItem key={type.id} value={type.bookSlug}>{type.bookName}</MenuItem>)}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Question</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {questions.map(question => (
              <TableRow key={question.id}>
                <TableCell>{question.label}</TableCell>
                <TableCell>{question.description}</TableCell>
                <TableCell>{question.questionType}</TableCell>
                <TableCell>
                  <div className={classes.actions}>
                    <Button
                      variant="contained"
                      onClick={() => navigate(`edit/${question.id}`)}
                    >
                      Edit
                    </Button>
                    <Button
                      color="secondary"
                      startIcon={<DeleteIcon />}
                      variant="contained"
                      onClick={() => handleDeleteQuestion(question.id)}
                    >
                      Delete
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ListQuestions;
