import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { IProductsState } from "shared/lib/models";
import { getProductsThunk } from "./thunks/getProducts.thunk";

export const productsExtraReducers = (
  builder: ActionReducerMapBuilder<IProductsState>
): ActionReducerMapBuilder<IProductsState> => builder
  .addCase(getProductsThunk.pending, state => {
    state.loading = true;
  })
  .addCase(getProductsThunk.fulfilled, (state, action) => {
    state.products = action.payload.data;
    state.totalPages = action.payload.totalPages;
    state.loading = false;
  })
  .addCase(getProductsThunk.rejected, state => {
    state.loading = false;
  });
