import { createAsyncThunk } from "@reduxjs/toolkit";

import questionsApi from "api/questionsApi";
import { prefix } from "./config";
import { ICreateQuestionPayload, IQuestion } from "shared/lib/models";

export const createQuestionThunk = createAsyncThunk<IQuestion, ICreateQuestionPayload>(`${prefix} Create Question`, async (payload, { rejectWithValue }) => {
  try {
    return await questionsApi.createQuestion(payload);
  } catch(err) {
    console.log(err);
    return rejectWithValue(err);
  }
});
