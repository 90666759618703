import * as Yup from "yup";
import { QuestionType } from "shared/lib/models";

export const questionSchema = Yup.object({
  label: Yup.string().required("Label is required!"),
  description: Yup.string(),
  questionType: Yup.string().oneOf([QuestionType.TEXT, QuestionType.TEXTAREA, QuestionType.CHOICE, QuestionType.CHECKBOX, QuestionType.DATE, QuestionType.FILE, QuestionType.RADIO, QuestionType.EVENT_DATE]).required("Question type is required!"),
  bookSlugs: Yup.array().of(Yup.string()).ensure().required("Book is required!"),
  required: Yup.boolean(),
  availableSideAnswer: Yup.boolean(),
  availableAnswers: Yup.array().of(Yup.string()).when("questionType", { is: (questionType: QuestionType) => [QuestionType.CHOICE, QuestionType.CHECKBOX, QuestionType.RADIO].indexOf(questionType) > -1, then: Yup.array().of(Yup.string()).required("Answers are required when [Choice, Checkbox, Radio] question type is selected") }),
  question_priority: Yup.number().moreThan(0, "Priority should be more than zero!")
});
