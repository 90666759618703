import { createAsyncThunk } from "@reduxjs/toolkit";

import questionsApi from "api/questionsApi";
import { prefix } from "./config";
import { IUpdateQuestionPayload, IQuestion } from "shared/lib/models";

export const updateQuestionThunk = createAsyncThunk<IQuestion, IUpdateQuestionPayload>(`${prefix} Update Question`, async (payload, { rejectWithValue }) => {
  try {
    return await questionsApi.updateQuestion(payload);
  } catch(err) {
    console.log(err);
    return rejectWithValue(err);
  }
});
