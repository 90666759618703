import React, { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, CircularProgress, Grid, Typography } from "@material-ui/core";

import classes from "./ViewProduct.module.scss";
import paths from "api/paths";
import { IProduct } from "shared/lib/models";
import { useAppDispatch, useFetch } from "hooks";
import { productStatusMapper } from "shared/lib/utils/productStatusMapper";
import { generatePDFsThunk } from "redux/products/thunks/generatePDFsThunk";
import dayjs from "dayjs";

const ViewProduct: React.FC = () => {
  const [generatingPDF, setGeneratingPDF] = useState(false);

  const { id: productId } = useParams();

  const {
    value: product,
    loading,
    error,
    refetch
  } = useFetch<IProduct>(paths.products.getOne.replace("{productId}", productId!));

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const generatePDFs = useCallback(async () => {
    if (!productId) return;

    setGeneratingPDF(true);

    await dispatch(generatePDFsThunk({ productId }));
    await refetch();

    setGeneratingPDF(false);
  }, [product]);

  return (
    <div className={classes.container}>
      {loading && <Typography variant="h5">loading...</Typography>}
      {error && (<div className="error">{error.message}</div>)}
      {product && (
        <>
          {generatingPDF && <div className={classes.loading}><CircularProgress /></div>}
          {!product.attached_to_wordpress_order ? (
            <Typography align="right" color="textSecondary">The product is not yet attached to an order</Typography>
          ) : (
            <Grid justifyContent="flex-end" spacing={4} container>
              {product.product_covers_pdf_slug && product.product_pages_pdf_slug ? (
                <>
                  <Grid item>
                    <Button
                      className={classes.downloadButton}
                      color="primary"
                      download={`${product.id}-covers.pdf`}
                      href={product.product_covers_pdf_slug}
                      target="__blank"
                      variant="contained"
                    >Download Covers
                      PDF</Button>
                  </Grid>
                  <Grid item>
                    <Button
                      className={classes.downloadButton}
                      color="primary"
                      download={`${product.id}-pages.pdf`}
                      href={product.product_pages_pdf_slug}
                      target="__blank"
                      variant="contained"
                    >Download Pages
                      PDF</Button>
                  </Grid>
                  {product.attached_to_wordpress_order_date && (
                    <Grid xs={12} item >
                      <Typography align="right">Product has been attached to an order on: <strong style={{ fontSize: 24 }}>{dayjs(product.attached_to_wordpress_order_date).format("DD/MM/YYYY HH:mm")}</strong></Typography>
                    </Grid>
                  )}
                </>
              ) : (
                <Grid item>
                  <Button className={classes.downloadButton} color="primary" variant="contained" onClick={generatePDFs}>Generate
                    PDFs</Button>
                </Grid>
              )}
            </Grid>
          )}
          <Typography variant="h5">Status: {productStatusMapper[product.status]}</Typography>
          <Typography variant="h5">Cover</Typography>
          <Grid spacing={4} container>
            <Grid xs={2} item onClick={() => navigate(`/products/${productId}/cover`, { state: { product } })}>
              <img src={`${product.cover.preview_image}?${performance.now()}`} width="100%" />
            </Grid>
          </Grid>
          <Typography variant="h5">Pages</Typography>
          <Grid spacing={4} container>
            {product.pages.map(page => (
              <Grid
                key={page.page_id}
                xs={2}
                item
                onClick={() => navigate(`/products/${productId}/${page.page_id}`, { state: { product } })}
              >
                <img
                  alt=""
                  src={`${page.preview_image}?${performance.now()}`}
                  width="100%"
                />
              </Grid>
            ))}
          </Grid>
          <Typography variant="h5">Back Cover</Typography>
          <Grid spacing={4} container>
            <Grid xs={2} item onClick={() => navigate(`/products/${productId}/back_cover`, { state: { product } })}>
              <img src={`${product.back_cover.preview_image}?${performance.now()}`} width="100%" />
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
};

export default ViewProduct;