import { createAsyncThunk } from "@reduxjs/toolkit";

import productsApi from "api/productsApi";
import { IProduct, ISaveGeneratedPDFsPayload } from "shared/lib/models";
import { prefix } from "./config";

export const generatePDFsThunk = createAsyncThunk<IProduct, ISaveGeneratedPDFsPayload>(`${prefix} Save Generated PDFs`, async (payload, { rejectWithValue }) => {
  try {
    return await productsApi.generate(payload);
  } catch(err) {
    console.log(err);
    return rejectWithValue(err);
  }
});
