import { createAsyncThunk } from "@reduxjs/toolkit";

import settingsApi from "api/settingsApi";
import { prefix } from "./config";
import { IUpdateSettingsPayload } from "shared/lib/models";

export const getSettingsThunk = createAsyncThunk<IUpdateSettingsPayload, undefined>(`${prefix} Get Settings`, async (_, { rejectWithValue }) => {
  try {
    return await settingsApi.getSettings();
  } catch(err) {
    console.log(err);
    return rejectWithValue(err);
  }
});
