import React, { useCallback, useEffect, useState } from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Button,
  FormControl, Grid, InputLabel, MenuItem,
  Paper, Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@material-ui/core";

import classes from "./ListTemplates.module.scss";
import { RootState } from "store";
import { IElement } from "models";
import { IBook, ITemplate } from "shared/lib/models";
import { useAppDispatch } from "hooks";
import { baseURL } from "config/constants";
import { getTemplatesThunk } from "redux/templates/thunks/getTemplates.thunk";
import { deleteTemplateThunk } from "redux/templates/thunks/deleteTemplate.thunk";
import { getBooksThunk } from "redux/books/thunks/getBooks.thunk";

const ListTemplates: React.FC = () => {
  const [templateType, setTemplateType] = useState("");
  const [bookType, setBookType] = useState("");

  const templates = useSelector<RootState, ITemplate<IElement>[]>(state => state.templates.templates);
  const bookTypes = useSelector<RootState, IBook[]>(state => state.books.books);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const deleteTemplate = useCallback(id => {
    dispatch(deleteTemplateThunk({ id, showAll: true, template_type: templateType, book_slug: bookType }));
  }, [templateType, bookType]);

  const handleTemplateTypeChange = useCallback(e => setTemplateType(e.target.value), []);

  const handleBookTypeChange = useCallback(e => setBookType(e.target.value), []);

  useEffect(() => {
    dispatch(getTemplatesThunk({ showAll: true, template_type: templateType || undefined, book_slug: bookType || undefined }));
  }, [templateType, bookType]);

  useEffect(() => {
    dispatch(getBooksThunk());
  }, []);

  return (
    <div className={classes.container}>
      <Button className={classes.newButton} color="primary" variant="contained" onClick={() => navigate("create")}>New</Button>
      <Grid spacing={3} container>
        <Grid xs={4} item>
          <FormControl variant="outlined" fullWidth>
            <InputLabel>Template Type</InputLabel>
            <Select
              id="templateType"
              label="Template Type"
              value={templateType}
              onChange={handleTemplateTypeChange}
            >
              <MenuItem value="">
                None
              </MenuItem>
              <MenuItem value="cover">Cover</MenuItem>
              <MenuItem value="page">Page</MenuItem>
              <MenuItem value="back_cover">Back Cover</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid xs={4} item>
          <FormControl variant="outlined" fullWidth>
            <InputLabel>Book Type</InputLabel>
            <Select id="bookType" label="Book Type" value={bookType} onChange={handleBookTypeChange}>
              <MenuItem value="">None</MenuItem>
              {bookTypes.map(type => <MenuItem key={type.id} value={type.bookSlug}>{type.bookName}</MenuItem>)}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <TableContainer className={classes.table} component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Template Type</TableCell>
              <TableCell>Preview Image</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {templates.map(template => (
              <TableRow key={template.id}>
                <TableCell>{template.name}</TableCell>
                <TableCell>{template.template_type}</TableCell>
                <TableCell><img alt="preview" src={template.preview_image.indexOf("http") > -1 ? template.preview_image : `${baseURL}${template.preview_image}`} width={124} /></TableCell>
                <TableCell>
                  <div className={classes.actions}>
                    <Button
                      variant="contained"
                      onClick={() => navigate(`edit/${template.id}`)}
                    >
                      Edit
                    </Button>
                    <Button
                      color="secondary"
                      startIcon={<DeleteIcon />}
                      variant="contained"
                      onClick={() => deleteTemplate(template.id)}
                    >
                      Delete
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ListTemplates;
