import axios, { AxiosError } from "axios";

import { baseURL } from "./constants";
import bugsnagClient from "./bugsnagClient";

const axiosInstance = axios.create({
  baseURL,
  headers: {
    Accept: "application/json",
  },
});

axiosInstance.interceptors.response.use(
  res => Promise.resolve(res.data),
  err => {
    const error = err as AxiosError;

    bugsnagClient.notify(error, (event) => {
      event.addMetadata("Axios", { ...error.response, request: JSON.parse(error.config.data) } || {});
    });

    return Promise.reject(error.response?.data);
  }
);

export default axiosInstance;
