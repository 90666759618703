import React from "react";
import classes from "./Button.module.scss";

interface IButtonProps {
  label: string;
  onClick: () => void
  big?: boolean
}

const Button: React.FC<IButtonProps> = ({ label, onClick, big }) => {
  return (
    <button className={`${classes.button} ${big ? classes.big : ""}`} onClick={onClick}>
      {label}
    </button>
  );
};

export default Button;
