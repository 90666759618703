import React, { useRef, useState } from "react";
import axios from "axios";
import SettingsIcon from "@material-ui/icons/Settings";
import { useToasts } from "react-toast-notifications";
import { animated, to, useSpring } from "react-spring";
import { useGesture } from "@use-gesture/react";

import classes from "./Image.module.scss";
import Modal from "../Modal";
import ImageSelectModalContent from "./ImageSelectModalContent";
import { uploadAttachmentThunk } from "redux/templates/thunks/uploadAttachment.thunk";
import { useAppDispatch } from "hooks";
import { useStateForInput } from "shared/lib/hooks";

export type ImageProps = {
  id: string;
  left: number;
  top: number;
  uri?: string;
  image_id?: string;
  width?: number;
  height?: number;
  rotation?: number;
  relatedQuestion?: string
};

type ImageComponentProps = ImageProps & {
  id: string;
  className: string;
  domTarget: React.RefObject<HTMLDivElement>;
  updateComponentProp: (
    id: string,
    key: keyof ImageProps | Record<string, unknown>,
    value?: unknown
  ) => void;
  deleteComponent: (id: string) => void;
};

const minWidthHeight = 10;

const Image: React.FC<ImageComponentProps> = ({
  className,
  domTarget,
  id,
  updateComponentProp,
  deleteComponent,
  ...props
}) => {
  const [uri, setUri] = useState(props.uri);
  const [, setFile] = useState<File | Blob | undefined>(undefined);
  const [width, , resetWidth] = useStateForInput(
    props.width?.toString() ?? "100"
  );
  const [height, , resetHeight] = useStateForInput(
    props.height?.toString() ?? "100"
  );
  const [openMenu, setOpenMenu] = useState(false);
  const [openImageModal, setOpenImageModal] = useState(false);

  const divRef = useRef<HTMLDivElement | null>(null);
  const menuRef = useRef<HTMLDivElement | null>(null);
  const cornerRefs = useRef<(HTMLDivElement | null)[]>([]);

  const dispatch = useAppDispatch();
  const { addToast } = useToasts();

  const [{ top, left }, api] = useSpring(() => ({
    top: 0,
    left: 0,
    config: {
      clamp: true,
    },
  }));

  useGesture({
    onDrag: ({ offset: [x, y], target }) => {
      if (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        cornerRefs.current.indexOf(target) > -1 ||
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        menuRef.current?.contains(target)
      )
        return;
      if (
        domTarget.current &&
        domTarget.current.clientHeight &&
        domTarget.current.clientWidth
      ) {
        api.start({
          left: (x / domTarget.current.clientWidth) * 100,
          top: (y / domTarget.current.clientHeight) * 100,
        });
      }
    },
    onDragEnd: ({ offset: [x, y] }) => {
      if (
        domTarget.current &&
        domTarget.current.clientHeight &&
        domTarget.current.clientWidth
      ) {
        updateComponentProp(id, {
          left: (x / domTarget.current.clientWidth) * 100,
          top: (y / domTarget.current.clientHeight) * 100,
        });
      }
    }
  },
  {
    drag: {
      bounds: domTarget.current!
    },
    target: divRef,
    eventOptions: { passive: false },
  }
  );

  const bindCorners = useGesture({
    onDrag: ({ delta: [x, y], target }) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (target.classList.contains(classes.bottomRight)) {
        resetWidth(String(Math.max(Number(width) + x, minWidthHeight)));
        resetHeight(String(Math.max(Number(height) + y, minWidthHeight)));
      }
    },
    onDragEnd: ({ delta: [x, y], target }) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (target.classList.contains(classes.bottomRight)) {
        updateComponentProp(id, {
          width: String(Math.max(Number(width) + x, minWidthHeight)),
          height: String(Math.max(Number(height) + y, minWidthHeight))
        });
      }
    }
  },
  {
    eventOptions: { passive: false },
  }
  );

  return (
    <>
      <animated.div
        ref={divRef}
        className={classes.container}
        draggable={false}
        style={{
          width: width + "px",
          height: height + "px",
          top: to([top], (top) => top + "%"),
          left: to([left], (left) => left + "%"),
          position: "absolute",
        }}
        tabIndex={0}
        onBlur={(e) => {
          if (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            e.currentTarget.contains(e.relatedTarget) ||
            e.currentTarget.textContent === ""
          )
            return;
          setOpenMenu(false);
        }}
      >
        <div
          ref={menuRef}
          className={classes.menu}
          style={{ opacity: openMenu ? 1 : 0 }}
        >
          <SettingsIcon color="action" onClick={() => setOpenImageModal(true)} />
          <button onClick={() => deleteComponent(id)}>delete</button>
        </div>
        <img
          className={className}
          draggable={false}
          src={uri}
          style={{
            width: width + "px",
            height: height + "px",
            transform: `rotate(${props.rotation}deg)`,
          }}
          onClick={() => setOpenMenu(true)}
          onDragStart={e => e.preventDefault()}
        />
        <div
          {...bindCorners()}
          ref={(ref) => cornerRefs.current.push(ref)}
          className={`${classes.resizePoint} ${classes.bottomRight}`}
        />
      </animated.div>
      <Modal isOpen={openImageModal} onClose={() => setOpenImageModal(false)}>
        <ImageSelectModalContent
          relatedQuestion={props.relatedQuestion}
          rotation={props.rotation}
          onSave={async ({ image, relatedQuestion, rotation }) => {
            setOpenImageModal(false);

            const updatePayload: { image_id?: string | File, relatedQuestion?: string | null, rotation?: number | number[] } = {};

            if (relatedQuestion !== null && rotation !== undefined) updatePayload.relatedQuestion = relatedQuestion;
            if (rotation !== null && rotation !== undefined) updatePayload.rotation = rotation;

            if (image) {
              let file = image;

              if (typeof image === "string") {
                try {
                  const { data } = await axios.get(image, {
                    responseType: "blob",
                  });
                  const urlSplit = image.split("/");
                  const filename = urlSplit[urlSplit.length - 1];

                  file = new File([data], filename);
                } catch (err) {
                  addToast("Couldn't load your image. Please contact admin!", {
                    appearance: "error"
                  });
                  return;
                }
              }
              const res = await dispatch(
                uploadAttachmentThunk({ file: file as File })
              ).unwrap();

              updatePayload["image_id"] = res.id;

              setFile(file as File);
              setUri(
                typeof image === "string" ? image : URL.createObjectURL(image)
              );
            }

            updateComponentProp(id, updatePayload);
          }}
        />
      </Modal>
    </>
  );
};

export default Image;
