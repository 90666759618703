import React from "react";
import { unstable_HistoryRouter as HistoryRouter, Routes as RRRoutes, Route } from "react-router-dom";
import { createBrowserHistory } from "history";

import Sidebar from "components/Sidebar";
import Home from "./Home";
import ListTemplates from "./Templates/ListTemplates";
import CreateTemplate from "./Templates/CreateTemplate";
import ListQuestions from "./Questions/ListQuestions";
import CreateQuestion from "./Questions/CreateQuestion";
import ListProducts from "./Products/ListProducts";
import ViewProduct from "./Products/ViewProduct";
import Config from "./Config";
import EditProductPage from "./Products/EditProductPage";

const history = createBrowserHistory({ window });

const Routes: React.FC = () => {
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <HistoryRouter history={history}>
      <Sidebar />
      <RRRoutes>
        <Route element={<Home />} path="/" />
        <Route path="/templates">
          <Route element={<ListTemplates />} index />
          <Route element={<CreateTemplate />} path="create" />
          <Route element={<CreateTemplate />} path="edit/:id" />
        </Route>
        <Route path="/questions">
          <Route element={<ListQuestions />} index />
          <Route element={<CreateQuestion />} path="create" />
          <Route element={<CreateQuestion />} path="edit/:id" />
        </Route>
        <Route path="/products">
          <Route element={<ListProducts />} index />
          <Route element={<ViewProduct />} path=":id" />
          <Route element={<EditProductPage />} path=":productId/:pageId" />
        </Route>
        <Route element={<Config />} path="/config" />
      </RRRoutes>
    </HistoryRouter>
  );
};

export default Routes;