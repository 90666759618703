import React from "react";

import classes from "./Home.module.scss";

const Home: React.FC = () => {
  return (
    <div className={classes.main} />
  );
};

export default Home;
