import { createAsyncThunk } from "@reduxjs/toolkit";

import languagesApi from "api/languagesApi";
import { ILanguage, Paginated } from "shared/lib/models";
import { prefix } from "./config";

export const getLanguagesThunk = createAsyncThunk<Paginated<ILanguage>, undefined>(`${prefix} Get Languages`, async (_, { rejectWithValue }) => {
  try {
    return await languagesApi.getLanguages();
  } catch(err) {
    console.log(err);
    return rejectWithValue(err);
  }
});
