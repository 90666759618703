import * as Yup from "yup";

export const selectObjectSchema = Yup.object({
  label: Yup.string().required(),
  value: Yup.string().required()
});

export const templateSchema = Yup.object({
  name: Yup.string().required("Template name is required"),
  background: Yup.string(),
  related_question_ids: Yup.array().of(Yup.string()).ensure(),
  language: Yup.string().required("Language is required"),
  template_type: Yup.string().oneOf(["page", "cover", "back_cover"]).nullable().required("Template type is required"),
  back_cover_id: Yup.string().notRequired().default(undefined).when("templateType", { is: (templateType: "page" | "cover" | "back_cover") => templateType && templateType === "cover", then: selectObjectSchema.required("You should select back cover when this template is of type cover") }),
  priority: Yup.number().moreThan(-41).lessThan(41)
});
