export default {
  books: {
    get: "/books"
  },
  questions: {
    get: "/questions",
    getOne: "/questions/{questionId}",
    post: "/questions",
    put: "/questions/{questionId}",
    delete: "/questions/{questionId}",
  },
  languages: {
    get: "/languages"
  },
  templates: {
    getOne: "/templates/{templateId}",
    get: "/templates",
    create: "/templates",
    put: "/templates/{templateId}",
    delete: "/templates/{templateId}"
  },
  attachments: {
    post: "/attachments"
  },
  products: {
    get: "/products",
    getOne: "/products/{productId}",
    generate: "/products/{productId}/generate",
    update: "/products/{productId}"
  },
  settings: {
    put: "/settings",
    get: "/settings"
  }
};
