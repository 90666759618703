import { createAsyncThunk } from "@reduxjs/toolkit";

import booksApi from "api/booksApi";
import { prefix } from "./config";
import { IBook, Paginated } from "shared/lib/models";

export const getBooksThunk = createAsyncThunk<Paginated<IBook>, undefined>(`${prefix} Get Books`, async (_, { rejectWithValue }) => {
  try {
    return await booksApi.getBooks();
  } catch(err) {
    console.log(err);
    return rejectWithValue(err);
  }
});
