import { createSlice } from "@reduxjs/toolkit";
import { booksInitialState } from "./books.initialState";
import { booksExtraReducers } from "./books.extraReducers";

export const booksSlice = createSlice({
  name: "books",
  initialState: booksInitialState,
  reducers: {},
  extraReducers: booksExtraReducers
});

export const { actions: booksActions } = booksSlice;
