import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { IQuestionsState } from "shared/lib/models";
import { getQuestionsThunk } from "./thunks/getQuestions.thunk";
import { getQuestionThunk } from "./thunks/getQuestion.thunk";

export const questionsExtraReducers = (
  builder: ActionReducerMapBuilder<IQuestionsState>
) => builder
  .addCase(getQuestionsThunk.fulfilled, (state, action) => {
    state.questions = action.payload.data;
  })
  .addCase(getQuestionThunk.fulfilled, (state, action) => {
    state.fetchedQuestion = action.payload;
  });
